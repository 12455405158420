import React from "react";
import { useEffect } from "react";
// import exitpng from "./images/exit.png";
function Filter({ selectedFilters, setSelectedFilters }) {
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const assetTypes = [
    // { id: 'Marketplace Listings', label: 'Marketplace Listings' },
    // { id: 'First Call Deck', label: 'First Call Deck' },
    { id: "Marketplace", label: "Marketplace" },
    { id: "Case studies", label: "Case studies" },
    { id: "Blog", label: "Blog" },
    { id: "Video", label: "Video" },
    { id: "E-Book", label: "Ebook" },
    { id: "Solution Brief", label: "Solution Brief" },
    { id: "Article", label: "Article" },
    { id: "Email", label: "Email" },
    // { id: 'Battlecard', label: 'Battlecard' },
    // { id: 'Qualifying Questions', label: 'Qualifying Questions' },
  ];

  const industries = [
    { id: "Internet Software", label: "Internet Software" },
    { id: "Manufacturing", label: "Manufacturing" },
    { id: "Healthcare and Life Science", label: "Healthcare and Life Science" },
    { id: "Financial Services", label: "Financial Services" },
    { id: "Media Entertainment", label: "Media Entertainment" },
    { id: "Gaming", label: "Gaming" },
    { id: "Energy", label: "Energy" },
  ];
  useEffect(() => {
    assetTypes.forEach((type) => {
      document.getElementById(type.id).checked =
        selectedFilters.assetTypes.includes(type.id);
    });
    industries.forEach((industry) => {
      document.getElementById(industry.id).checked =
        selectedFilters.industries.includes(industry.id);
    });
  }, [selectedFilters]);

  const handleFilterChange = (event) => {
    const filterId = event.target.id;
    let newFilters;

    if (assetTypes.find((type) => type.id === filterId)) {
      newFilters = {
        ...selectedFilters,
        assetTypes: selectedFilters.assetTypes.includes(filterId)
          ? selectedFilters.assetTypes.filter((f) => f !== filterId)
          : [...selectedFilters.assetTypes, filterId],
      };
    } else if (industries.find((type) => type.id === filterId)) {
      newFilters = {
        ...selectedFilters,
        industries: selectedFilters.industries.includes(filterId)
          ? selectedFilters.industries.filter((f) => f !== filterId)
          : [...selectedFilters.industries, filterId],
      };
    }

    setSelectedFilters(newFilters);

    // Update URL
    const params = new URLSearchParams(window.location.search);
    params.set("filters", JSON.stringify(newFilters));
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
  };

  return (
    <>
      <div className="flex flex-row sm:gap-10 ">
        <div className="sm:w-full sm:max-w-[18rem]">
          <input
            type="checkbox"
            id="sidebar-mobile-fixed"
            className="sidebar-state"
          />
          <label
            htmlFor="sidebar-mobile-fixed"
            className="sidebar-overlay"
          ></label>
          <aside className="sidebar sidebar-mobile flex justify-start max-sm:fixed max-sm:-translate-x-full">
            <section className="sidebar-content border-r-2">
              <nav className="menu rounded-md">
                <section className="menu-section pl-4">
                  <span className="font-bold pl-2">Filters</span>
                  <ul className="menu-items">
                    <li>
                      <label className="menu-item justify-between">
                        <div className="flex gap-2">
                          <span>Asset Types</span>
                        </div>
                      </label>

                      <div className="min-h-0">
                        {assetTypes.map((type) => (
                          <li key={type.id}>
                            <label htmlFor={type.id} className="menu-item ">
                              <input
                                type="checkbox"
                                id={type.id}
                                className="h-4 w-4 ml-3 rounded border-gray-300"
                                onChange={handleFilterChange}
                              />
                              <span className="text-sm font-medium text-gray-500">
                                {type.label}
                              </span>
                            </label>
                          </li>
                        ))}
                      </div>
                    </li>
                    <li>
                      <label className="menu-item justify-between">
                        <div className="flex gap-2">
                          <span>Industry</span>
                        </div>
                      </label>

                      <div className="min-h-0">
                        {industries.map((type) => (
                          <li key={type.id}>
                            <label htmlFor={type.id} className="menu-item ">
                              <input
                                type="checkbox"
                                id={type.id}
                                className="h-4 w-4 ml-3 rounded border-gray-300"
                                onChange={handleFilterChange}
                              />
                              <span className="text-sm font-medium text-gray-500">
                                {type.label}
                              </span>
                            </label>
                          </li>
                        ))}
                      </div>
                    </li>
                  </ul>
                </section>
                <div className="Logout-comp sidebar-footer h-[18vh]">
                  {/* <div className="divider my-0"></div>
              <div className="dropdown z-50 flex h-fit w-full cursor-pointer hover:bg-gray-4">
                <label className="whites mx-2 flex justify-center h-fit w-full cursor-pointer p-0 hover:bg-gray-4" tabIndex="0">
                  <div className="flex flex-row gap-4 justify-center items-center">
                    <div className="avatar-square avatar-sm">
                      <img src={exitpng} alt="avatar" />
                    </div>

                    <div className="flex flex-col">
                      <span><a href='index.html'>Logout</a></span>
                    </div>
                  </div>
                </label>
              </div>
              <div className="divider my-0"></div> */}
                </div>
                <section></section>
              </nav>
            </section>
          </aside>
          <div className="flex flex-row sm:gap-10">
            {/* Filter Image */}
            <div className="w-fit">
              <label
                htmlFor="sidebar-mobile-fixed"
                className="btn-primary btn bg-transparent sm:hidden fixed bottom-7 left-0 z-50"
              >
                <img
                  src="https://img.icons8.com/ios-filled/50/FFFFFF/empty-filter.png"
                  alt="Contains a pic"
                  className=" h-14 w-14 p-3 bg-gray-700 rounded-full"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
