//reacttails/src/App.js

import "./customstyles.css";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import Main from "./Main";
import Filter from "./filter";
import Introslider from "./Introslider";
import LoginPage from "./Login";

const App = () => {
  const [navTitle, setNavTitle] = useState("");
  const [isintroSelected, setIsIntroSelected] = useState(true);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const category = params.get("category");
    const filters = params.get("filters");

    if (category) {
      setNavTitle(category);
      setIsIntroSelected(false);
    }

    if (filters) {
      try {
        const parsedFilters = JSON.parse(filters);
        setSelectedFilters(parsedFilters);
      } catch (e) {
        console.error("Error parsing filters from URL:", e);
      }
    }
  }, []);
  const handleNavbarItemClick = (selectedItem) => {
    setNavTitle(selectedItem);
    setIsIntroSelected(false);
    updateURL(selectedItem, selectedFilters);
  };

  const updateURL = (category, filters) => {
    const params = new URLSearchParams();
    if (category) params.set("category", category);
    if (filters) params.set("filters", JSON.stringify(filters));
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
  };
  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
    updateURL(navTitle, newFilters);
  };
  function resetFilters() {
    console.log("Filters are reset");
    setSelectedFilters({
      assetTypes: [],
      industries: [],
    });
  }

  const [selectedFilters, setSelectedFilters] = useState({
    assetTypes: [],
    industries: [],
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLogin = (password) => {
    const validPassword = "y1Mga7R2TrCb";

    if (password === validPassword) {
      setIsLoggedIn(true);
    } else {
      alert("Invalid password");
    }
  };

  return (
    <div className="font-poppins h-screen">
      {/* {isLoggedIn ? (
        <div className="flex flex-col h-full">
          <Navbar onItemClick={handleNavbarItemClick} setIsIntroSelected={setIsIntroSelected} resetFilters={resetFilters} className="bg-gray-100" />
          {isintroSelected ? (
            <Introslider/>
          ) : (
            <div className='max-w-screen-3xl ml-auto mr-auto'>
              <div className="flex flex-1 ">
                <div className="flex flex-col h-full bg-gray-100">
                  <Filter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </div>
                <div className="flex-1 overflow-y-auto bg-white">
                  <div>
                    <Header navTitle={navTitle} />
                    <Main selectedFilters={selectedFilters} navTitle={navTitle} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoginPage handleLogin={handleLogin} />
      )} */}
      <div className="flex flex-col h-full">
        <Navbar
          onItemClick={handleNavbarItemClick}
          setIsIntroSelected={setIsIntroSelected}
          resetFilters={resetFilters}
          className="bg-gray-100"
        />
        {isintroSelected ? (
          <Introslider />
        ) : (
          <div className="max-w-screen-3xl ml-auto mr-auto">
            <div className="flex flex-1 ">
              <div className="flex flex-col h-full bg-gray-100">
                <Filter
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </div>
              <div className="flex-1 overflow-y-auto bg-white">
                <div>
                  <Header navTitle={navTitle} />
                  <Main selectedFilters={selectedFilters} navTitle={navTitle} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
