// { Schema for the dataformat
//   AssetType: "Case studies",
//   Industry: "Internet Software",
//   Title: "DocuSign",
//   Description:
//     "High security enterprise data mesh for data science and advnace analytics",
//   ButtonText: "Download",
//   URL: "Highly Compliant Data Platform to provide Actionable Insights",
//   Links:
//     "https://mactores.com/highly-compliant-data-platform-to-provide-actionable-insights",
//   ImageURL:
//     "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/DocuSign_Case_Study_Featured_Image.jpg",
// }
const ListData = {
  dataLakeList: [
    {
      AssetType: "Case studies",
      Industry: "Internet Software",
      Title: "DocuSign",
      Description:
        "High security enterprise data mesh for data science and advnace analytics",
      ButtonText: "Download",
      URL: "Highly Compliant Data Platform to provide Actionable Insights",
      Links:
        "https://mactores.com/highly-compliant-data-platform-to-provide-actionable-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/DocuSign_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Internet Software",
      Title: "Flipboard",
      Description: "Modernizing Flipboard’s Hadoop-backed database ",
      ButtonText: "Download",
      URL: "Modernizing Flipboard’s Hadoop-backed database ",
      Links:
        "https://mactores.com/case-study-modernizing-flipboard-hadoop-backed-database",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Flipboard_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Manufacturing",
      Title: "Seagate",
      Description:
        "Modernizing Factory Operations using Enterprise Data Lake Transformation using Mactores Data Lake framework",
      ButtonText: "Download",
      URL: "Modernizing Factory Operations using Enterprise Data Lake",
      Links:
        "https://mactores.com/modernizing-factory-operations-using-enterprise-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Seagate_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Manufacturing",
      Title: "Synaptics",
      Description: "AI-backed operational data lake for Synaptics",
      ButtonText: "Download",
      URL: "AI-backed operational data lake for Synaptics",
      Links:
        "https://mactores.com/case-study-ai-backed-operational-data-lake-for-synaptics",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Synaptics_Data_Lake_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Manufacturing",
      Title: "HP",
      Description: "Smart Factory - Control And Command Center",
      ButtonText: "Download",
      URL: "Smart Factory - Control And Command Center",
      Links: "https://mactores.com/smart-factory-control-and-command-center",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/HP_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Healthcare and Life Science",
      Title: "EMD Serono",
      Description:
        "Modernize Supply Chain And Distribution With Agile Data & ML Platform",
      ButtonText: "Download",
      URL: "Modernize Supply Chain And Distribution With Agile Data & ML Platform",
      Links:
        "https://mactores.com/modernize-supply-chain-and-distribution-with-agile-data-ml-platform",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/EMD_Serono_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Financial Services",
      Title: "Cello Capital",
      Description:
        "Mortgage-backed Securities (MBS) Pricing and Analytics platform",
      ButtonText: "Download",
      URL: "Mortgage-backed Securities (MBS) Pricing and Analytics platform",
      Links:
        "https://mactores.com/mortgage-backed-securities-pricing-and-analytics-platform",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Cello_Capital_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Gaming",
      Title: "Nazara",
      Description: "Online Gaming Giant Achieves Enhanced Gaming Experience",
      ButtonText: "Download",
      URL: "Online Gaming Giant Achieves Enhanced Gaming Experience",
      Links:
        "https://mactores.com/online-gaming-giant-achieves-enhanced-gaming-experience",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Nazara_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Energy",
      Title: "Adani Power",
      Description:
        "Power Generation Company Improves Operational Efficiency with AWS and Mactores",
      ButtonText: "Download",
      URL: "Power Generation Company Boost Productivity with AWS and Mactores",
      Links:
        "https://mactores.com/power-generation-company-boost-productivity-with-aws-and-mactores",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Adani_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Media Entertainment",
      Title: "Hansa Cequity",
      Description: "Multi-Channel Marketing Campaign Optimization at Scale",
      ButtonText: "Download",
      URL: "Multi-Channel Marketing with Contextual Intelligence",
      Links:
        "https://mactores.com/multi-channel-marketing-with-contextual-intelligence",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Hansa_Cequity_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Are You Unlocking the Power of Your Data? Enhance your Analytics with Mactores Aedeon Data Lake",
      Description:
        "Discover how Mactores Aedeon Data Lake simplifies data management, provides a cost-effective analytics pipeline and empowers real-time decision-making. ",
      ButtonText: "View",
      URL: "Are You Unlocking the Power of Your Data? Enhance your Analytics with Mactores Aedeon Data Lake",
      Links:
        "https://mactores.com/blog/enhancing-your-analytics-platforms-with-mactores-aedeon-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Unlocking%20Power%20Of%20Your%20Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Data Lakehouse: An Open Alternative to Data lake and Data Warehouse For High ROI Big Data Applications",
      Description:
        "Let's look at the advantages of a Data Lakehouse vs a Data Lake vs a Data Warehouse in terms of which can provide High ROI Big Data Applications",
      ButtonText: "View",
      URL: "Data Lakehouse: An Open Alternative to Data lake and Data Warehouse For High ROI Big Data Applications",
      Links:
        "https://mactores.com/blog/data-lakehouse-an-open-alternative-to-data-lake-and-data-warehouse-for-high-roi-big-data-applications",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/DataLakehouse%20An%20Open%20Alternative.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Data Lakehouses in Healthcare and what are it's Benefits",
      Description:
        "Discover the benefits of data lakehouses in healthcare, overcoming data management challenges and improving patient care with efficient data security",
      ButtonText: "View",
      URL: "Data Lakehouses in Healthcare and what are it's Benefits",
      Links:
        "https://mactores.com/blog/data-lakehouses-in-healthcare-and-what-are-its-benefits",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Datalakehouse%20In%20Healthcare.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Top 7 Analytics Use Cases for Aedeon Data Lake",
      Description:
        "Aedeon Data Lake use cases Marketing, Customer 360, Network Performance, Cybersecurity, Predictive Support, Predictive Maintenance, & more.",
      ButtonText: "View",
      URL: "Top 7 Analytics Use Cases for Aedeon Data Lake",
      Links:
        "https://mactores.com/blog/https/mactores.com/blog/top-7-analytics-use-cases-for-aedeon-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/7%20Analytics%20Use%20Case.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "DocuSign Accelerates DataOps with Mactores Aedeon Data Lake",
      Description:
        "Learn how we helped DocuSign improve DataOps with the Mactores Aedeon Data Lake solution. This helped them drive key insights from their business.",
      ButtonText: "View",
      URL: "DocuSign Accelerates DataOps with Mactores Aedeon Data Lake",
      Links:
        "https://mactores.com/blog/docusign-accelerates-dataops-with-mactores-aedeon-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/DocuSign%20Accelerates%20DataOps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Industry Data Analytics for Different Verticals Using Aedeon Data Lake",
      Description:
        "This blog discusses how industry data analytics for manufacturing, healthcare, financial, legal, and others can leverage Aedeon Data Lake.",
      ButtonText: "View",
      URL: "Industry Data Analytics for Different Verticals Using Aedeon Data Lake",
      Links:
        "https://mactores.com/blog/industry-data-analytics-for-different-verticals-using-aedeon-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Industry%20Data%20Analytics.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How Data Lake Solves Key Challenges in Analytics?",
      Description:
        "Mactores will review how data lake technologies differentiate from traditional alternatives to solve some of the key challenges facing analytics use cases.",
      ButtonText: "View",
      URL: "How Data Lake Solves Key Challenges in Analytics?",
      Links:
        "https://mactores.com/blog/how-data-lake-solves-key-challenges-in-analytics",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Datalake%20Challenges.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Data Lake: The Key to Getting Value Out of Your Data",
      Description:
        "Data Lakes store data in flat architecture, allowing for structured/unstructured files from multiple sources/formats.",
      ButtonText: "View",
      URL: "Data Lake: The Key to Getting Value Out of Your Data",
      Links:
        "https://mactores.com/blog/data-lake-the-key-to-getting-value-out-of-your-data",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Key%20to%20your%20data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What Is a Data Mesh and How Does It Help with Governance?",
      Description:
        "What is a data mesh? This guide gives you everything you need to know about this data platform architecture and modern approach to data governance.",
      ButtonText: "View",
      URL: "What Is a Data Mesh and How Does It Help with Governance?",
      Links:
        "https://mactores.com/blog/what-is-a-data-mesh-and-how-does-it-help-with-governance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Datamesh.jpg",
    },
    {
      AssetType: "Video",
      Industry: "Manufacturing",
      Title: "Manufacturing Datalake Transformation with Mactores",
      Description:
        "Dan Marks, Nandan Umarji, and Bal Heroor discuss how Mactores helped a large hard drive manufacturing customer identify the right solution to solve their business challenges.",
      ButtonText: "View",
      URL: "Manufacturing Datalake Transformation with Mactores - YouTube",
      Links: "https://www.youtube.com/watch?v=LWhAJ2Y8nBQ",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Key%20to%20your%20data.jpg",
    },
    {
      AssetType: "Ebook",
      Industry: "Internet Software",
      Title: "Flipbook - Aedeon Data Lake: Empower everyone",
      Description:
        "How Mactores’ outcome-driven framework ensures that your critical business needs are met",
      ButtonText: "Download",
      URL: "Aedeon Data Lake: Empower everyone",
      Links:
        "https://mactores.com/hubfs/Insights/Aedeon%20Data%20Lake%20Empower%20everyone.pdf?hsLang=en",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Flipbook_Aedeon_Data_Lake.jpg",
    },
    {
      AssetType: "Solution Brief",
      Industry: "Internet Software",
      Title: "Aedeon® Data Lake: Think big, invest less, move fast",
      Description:
        "Aedeon® Data Lake platform is integrated with AWS Analytics Services customized with Mactores™ professional services for faster time to market",
      ButtonText: "Download",
      URL: "Aedeon® Data Lake: Think big, invest less, move fast",
      Links:
        "https://mactores.com/hubfs/Insights/Mactores%20Aedeon%20Datalake%20Solution%20Brief%20v3.pdf?hsLang=en",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Solution_Brief_Aedeon_Data_Lake.jpg",
    },
    {
      AssetType: "Solution Brief",
      Industry: "Internet Software",
      Title: "Revolutionize Data Insights with ChatGPT",
      Description:
        "Improve productivity by 5x using ChatGPT™ Integration with Mactores™ Aedeon® Data Lake on AWS, Amazon Redshift, and Snowflake",
      ButtonText: "Download",
      URL: "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      Links:
        "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/ChatGPT_Integration.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Healthcare and Life Science",
      Title: "Data Lake houses in Healthcare and their benefits",
      Description:
        "Discover how data lake houses can be beneficial in healthcare industries",
      ButtonText: "View",
      URL: "Data Lake houses in Healthcare and their benefits",
      Links:
        "https://www.healthcareittoday.com/2023/04/11/current-healthcare-analytics-challenges-require-a-collaborative-approach-to-problem-solving/",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Article_Data_Lakehouse.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title:
        "Mactores Announces ChatGPT Integration for Its Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake",
      Description:
        "Mactores, a leading provider in data analytics, ML, and AI systems, announces the successful integration of ChatGPT into its Mactores Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake. This integration makes it easier for business users to interact with their data and derive usable insights.",
      ButtonText: "View",
      URL: "Mactores Announces ChatGPT Integration for Its Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake",
      Links:
        "https://www.accesswire.com/745140/Mactores-Announces-ChatGPT-Integration-for-Its-Aedeon-Data-Lake-on-AWS-Amazon-Redshift-and-Snowflake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/ChatGPT_Integration.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title: "How IoT Architects Can Tap into the Benefits of a Data Lakehouse",
      Description:
        "This article explores the key features and benefits of data lakehouses and provides best practices for IoT architects",
      ButtonText: "View",
      URL: "How IoT Architects Can Tap into the Benefits of a Data Lakehouse",
      Links:
        "https://iotbusinessnews.com/2023/03/06/15882-how-iot-architects-can-tap-into-the-benefits-of-a-data-lakehouse/",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/IOT_Architects.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of Amazon Data Lake with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance big data processing and analytics capabilities with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon Data Lake",
      ButtonText: "View",
      URL: "Amazon Data Lake Email",
      Links:
        "https://docs.google.com/document/d/1B_WKGyfxCXPniE5IZX2qu7xXU90IPPOkj0h3y4KO9CY/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Data%20Lake.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "Accelerating Hybrid-Cloud Adoption in Banking and Securities",
      Description:
        "Explore finance-tech synergy: Hybrid cloud in banking for flexibility, scalability, cost, and optimization",
      ButtonText: "View",
      URL: "https://mactores.com/blog/accelerating-hybrid-cloud-adoption-in-banking-and-securities",
      Links:
        "https://mactores.com/blog/accelerating-hybrid-cloud-adoption-in-banking-and-securities",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Hybrid_Cloud_Banking.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Importance of Data Protection and How Cloud Makes It Easy",
      Description:
        "Discover cloud data protection: security, compliance, and disaster recovery. Real-world examples for scalable solution",
      ButtonText: "View",
      URL: "https://mactores.com/blog/importance-of-data-protection-and-how-cloud-makes-it-easy",
      Links:
        "https://mactores.com/blog/importance-of-data-protection-and-how-cloud-makes-it-easy",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Protection_Importance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Step-by-Step Guide to Building a Data Lake",
      Description:
        "Want to take your business to the next level with meaningful insights? Learn how to build your own data lake to unlock the potential of your data. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/step-by-step-guide-to-building-a-data-lake",
      Links:
        "https://mactores.com/blog/step-by-step-guide-to-building-a-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Step_By_Step-1.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Mactores: Leading Innovation with Advanced Data Solutions",
      Description:
        "Discover how Mactores drives innovation with advanced data solutions, empowering businesses for success in the data-driven future. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/mactores-leading-innovation-with-advanced-data-solutions",
      Links:
        "https://mactores.com/blog/mactores-leading-innovation-with-advanced-data-solutions",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Mactores_Leading_Innovation.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Analytics Renaissance: Mactores Unleashing the Power of Big Data",
      Description:
        "Discover how Mactores pioneers the analytics renaissance, unlocking big data's power for transformative insights and business success.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/analytics-renaissance-mactores-unleashing-the-power-of-big-data",
      Links:
        "https://mactores.com/blog/analytics-renaissance-mactores-unleashing-the-power-of-big-data",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Analytics_Renaissance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "New CIO's Guide to a Successful First 100 Days",
      Description:
        "Maximize your impact as a new CIO with actionable advice and best practices for your first 100 days.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/new-cios-guide-to-a-successful-first-100-days",
      Links:
        "https://mactores.com/blog/new-cios-guide-to-a-successful-first-100-days",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/CIO_100.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Cloud Revolution: Mactores' Strategies in Driving Cloud Adoption",
      Description:
        "Explore Mactores' pioneering strategies in driving cloud adoption, with a focus on AWS solutions, methodologies, and real-world case studies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/cloud-revolution-mactores-strategies-in-driving-cloud-adoption",
      Links:
        "https://mactores.com/blog/cloud-revolution-mactores-strategies-in-driving-cloud-adoption",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Cloud_Revolution.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Moving to the Cloud: How to Convince Your CFO",
      Description:
        "Learn to convince your CFO of the cloud's value with strategies focusing on cost, scalability, security, and addressing common concerns.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/moving-to-the-cloud-how-to-convince-your-cfo",
      Links:
        "https://mactores.com/blog/moving-to-the-cloud-how-to-convince-your-cfo",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Moving_CFO.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What is Data Governance?",
      Description:
        "Unlock the power of data with effective data governance. Learn how to manage, protect, and maximize the value of your organization's most valuable asset.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-is-data-governance",
      Links: "https://mactores.com/blog/what-is-data-governance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Governance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What is Data Governance and Why Does it Matter?",
      Description:
        "Discover the importance of data governance in today's data-driven world. Learn how it mitigates risks, fosters trust, and unlocks data's true value.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-is-data-governance-and-why-does-it-matter?",
      Links:
        "https://mactores.com/blog/what-is-data-governance-and-why-does-it-matter?",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Governance_Matters.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Data Management vs. Data Governance: The Difference Explained",
      Description:
        "Discover the difference between data management and data governance. Learn how each function contributes to building a robust data ecosystem for success. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-management-vs-data-governance-the-difference-explained",
      Links:
        "https://mactores.com/blog/data-management-vs-data-governance-the-difference-explained",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Governance_Data_Management.png",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Building a Data Governance Strategy in 7 Steps",
      Description:
        "Discover how to build a robust data governance strategy in 7 steps, ensuring data integrity, compliance, and value maximization.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/building-a-data-governance-strategy-in-7-steps",
      Links:
        "https://mactores.com/blog/building-a-data-governance-strategy-in-7-steps",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Governance_Strategy.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Designing Data Governance that Delivers Value",
      Description:
        "Unlock the potential of data governance: align with business goals, engage stakeholders, and prioritize initiatives for impact.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/designing-data-governance-that-delivers-value",
      Links:
        "https://mactores.com/blog/designing-data-governance-that-delivers-value",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Governance_Value.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Data Lakes: Enhancing Patient Outcomes and Operational Efficiency",
      Description:
        "Transform healthcare by enhancing patient outcomes and operational efficiency through comprehensive data storage, analysis, and personalized care.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-lakes-enhancing-patient-outcomes-and-operational-efficiency",
      Links:
        "https://mactores.com/blog/data-lakes-enhancing-patient-outcomes-and-operational-efficiency",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Enhancing_Healthcare.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What Is a Data Lake? Is It Better Than Data Warehouses?",
      Description:
        "Data Lakes vs. Data Warehouses: Discover the flexibility, scalability, and use cases of data lakes and how they compare to traditional data warehouses.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-is-a-data-lake-is-it-better-than-data-warehouses",
      Links:
        "https://mactores.com/blog/what-is-a-data-lake-is-it-better-than-data-warehouses",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Better_Data_Warehouse.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Data Lakes in Retail: Customer Experience and Supply Chain Enhancement",
      Description:
        "Data lakes enhance customer experience and supply chain management in retail, offering scalable solutions for real-time analytics and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-lakes-in-retail-customer-experience-and-supply-chain-enhancement",
      Links:
        "https://mactores.com/blog/data-lakes-in-retail-customer-experience-and-supply-chain-enhancement",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Retail.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Data Lakes in Manufacturing: Predictive Maintenance & Quality Control",
      Description:
        "Data Lakes boosts manufacturing with predictive maintenance and quality control, enhancing efficiency, reducing costs, and ensuring top product quality.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-lakes-in-manufacturing-predictive-maintenance-quality-control",
      Links:
        "https://mactores.com/blog/data-lakes-in-manufacturing-predictive-maintenance-quality-control",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Challenges and Solutions for Advanced Customer Segmentation with AWS Technologies",
      Description:
        "Learn how to overcome challenges in advanced customer segmentation with AWS technologies. Explore Delta-Lake architecture and AWS solutions for robust data infrastructure. Let's talk for implementation.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/challenges-and-solutions-for-advanced-customer-segmentation-with-aws-technologies",
      Links:
        "https://mactores.com/blog/challenges-and-solutions-for-advanced-customer-segmentation-with-aws-technologies",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Advanced_Customer_Segmentation.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "A Step-by-Step Guide to Implementing an Enterprise Data Lake",
      Description:
        "Implement an enterprise data lake with our guide on governance, access control, and optimization.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/a-step-by-step-guide-to-implementing-an-enterprise-data-lake",
      Links:
        "https://mactores.com/blog/a-step-by-step-guide-to-implementing-an-enterprise-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Step_By_Step_Enterprise_Data_Lake.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How Can a Data Lake Drive Business Insights?",
      Description:
        "Discover how data lakes drive business insights, innovation, competitive edge through cost-efficient, scalable storage and analysis of vast, diverse data.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-can-a-data-lake-drive-business-insights",
      Links:
        "https://mactores.com/blog/how-can-a-data-lake-drive-business-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Business_Insight.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Convince Your C-Suite With Business Case for a Data Lake",
      Description:
        "Craft a persuasive business case for a data lake to convince your C-suite. Highlight its benefits, financial impact, and implementation plan.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/convince-your-c-suite-with-business-case-for-a-data-lake",
      Links:
        "https://mactores.com/blog/convince-your-c-suite-with-business-case-for-a-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Convince_CSuite_Data_Lake.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Taming Data Deluge: Best Practices for Data Governance in a Data Lake",
      Description:
        "Ensure data quality, security, compliance, and cost efficiency with clear ownership, catalogs, and robust policies with best practices.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/taming-data-deluge-best-practices-for-data-governance-in-a-data-lake",
      Links:
        "https://mactores.com/blog/taming-data-deluge-best-practices-for-data-governance-in-a-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Deluge_Data_Lake.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title:
        "Data Lakes in Energy: Empower Smart Grids and Sustainable Operations",
      Description:
        "Check how data lakes enhance smart grids and sustainable energy operations, improving efficiency, decision-making, and sustainability in the energy sector.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-lakes-in-energy-empower-smart-grids-and-sustainable-operations",
      Links:
        "https://mactores.com/blog/data-lakes-in-energy-empower-smart-grids-and-sustainable-operations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Lake_Energy.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How to Clean Data for an Enterprise Data Lake?",
      Description:
        "Ensure trustworthy analytics and innovation with clean data in your enterprise data lake. Learn the strategic steps for data cleansing and its importance.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-to-clean-data-for-an-enterprise-data-lake",
      Links:
        "https://mactores.com/blog/how-to-clean-data-for-an-enterprise-data-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Enterprise_Data_Lake_Clean.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Security Considerations for Enterprise Data Lakes",
      Description:
        "Secure your enterprise data lake with encryption, access control, strong governance to protect against breaches, data theft, and regulatory non-compliance.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/security-considerations-for-enterprise-data-lakes",
      Links:
        "https://mactores.com/blog/security-considerations-for-enterprise-data-lakes",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Security_Considerations_Data_Lake.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Future-Proofing Data-Driven Businesses",
      Description:
        "Future-proof your data strategy with AI, real-time discovery, privacy, and scalable infrastructure to stay competitive in the evolving digital landscape.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/future-proofing-data-driven-businesses",
      Links: "https://mactores.com/blog/future-proofing-data-driven-businesses",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Future_Proof_Data_Driven.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Data Platform Modernization Strategy Workshop",
      Description:
        "Mactores® Data Platform Modernization Strategy Workshop is a comprehensive, tailored program that helps organizations assess their current data infrastructure and develop a customized plan for modernizing their data platforms.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-ithgmzqdwui3c?sr=0-6&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-ithgmzqdwui3c?sr=0-6&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Data%20Platform%20Modernization%20Strategy%20Workshop.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Aedeon Data Fabric for Digital Twining",
      Description:
        "Aedeon Data Fabric for Digital Twinning enables organizations to build, manage, and optimize digital twins seamlessly. Aedeon Data Lake unifies data across disparate sources to provide real-time insights and actionable intelligence for multiple industries.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-a7cfj6h6be74c?sr=0-34&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-a7cfj6h6be74c?sr=0-34&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Aedeon%20Data%20Fabric%20for%20Digital%20Twining%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Data Fabric For Digital Twinning In Manufacturing",
      Description:
        "Introducing the Data Fabric Data Lake Platform for Digital Twinning in Manufacturing, an advanced solution powered by AWS serverless analytics services.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-5x67k6tvdafdg?sr=0-35&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-5x67k6tvdafdg?sr=0-35&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Fabric%20For%20Digital%20Twinning%20In%20Manufacturing.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
  ],
  emrList: [
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title:
        "Databricks to Amazon EMR and Amazon Sagemaker Migration Assessment",
      Description:
        "Are you looking to migrate your Databricks environment to Amazon EMR with Apache Hudi or Apache IceBerg and Amazon Sagemaker? Let Mactores help you with our comprehensive migration assessment service",
      ButtonText: "View",
      URL: "Databricks to Amazon EMR and Amazon Sagemaker Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-jzf43qyfowi56?sr=0-4&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Databricks%20to%20Amazon%20EMR%20and%20Amazon%20Sagemaker%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Cloudera to Amazon EMR Migration Assessment",
      Description:
        "Migrate your Cloudera Cluster to Amazon EMR with ease. Introducing Mactores' Comprehensive Migration Assessment Service, designed to help you make the transition to Apache HBase, Apache Kafka, and Apache Spark. Our 100% automated accelerators and team of experts will analyze your current usage and requirements to determine the best way to migrate your Clusters, Jobs, Libraries, Storage, Streams, Access Control, and Security. We will also evaluate compatibility with your existing data and code and provide a detailed analysis of additional costs and benefits",
      ButtonText: "View",
      URL: "Cloudera to Amazon EMR Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-5vsy4a5ms7mu4?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Cloudera%20to%20Amazon%20EMR%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Aedeon Migration Suite for Apache HBase to Amazon EMR",
      Description:
        "Aedeon Migration Suite for Apache HBase to Amazon EMR is a powerful, comprehensive solution designed to streamline the complex process of data migration",
      ButtonText: "View",
      URL: "Aedeon Migration Suite for Apache HBase to Amazon EMR",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-rchyhb4yi5qvg?sr=0-6&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Aedeon%20Migration%20Suite%20for%20Apache%20HBase%20to%20Amazon%20EMR.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Driven Everything Workshop and MVP",
      Description:
        'Our "Data Driven Everything Workshop" is designed to guide businesses through the necessary steps to make this shift, including defining customer personas and their problems, creating data-driven value stories, and visualizing future state experiences. The MVP will involve building data pipelines using relevant AWS Services like Amazon S3, Amazon EMR, AWS Glue, Amazon Redshift, and Amazon Sagemaker.',
      ButtonText: "View",
      URL: "Data Driven Everything Workshop and MVP",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-urzzrqrd7hzmo?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Driven%20Everything%20Workshop%20and%20MVP.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Efficient Hadoop EMR Migration: Automation for Development & Cost",
      Description:
        "Mactores EMR Accelerator automates workload dependency discovery in Cloudera, Hortonworks, or MapR systems, mapping to Amazon EMR for scalable automation.",
      ButtonText: "View",
      URL: "Efficient Hadoop EMR Migration: Automation for Development & Cost",
      Links:
        "https://mactores.com/blog/efficient-hadoop-emr-migration-automation-for-development-cost",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Hadoop%20EMR%20Presto%20SQL.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How to modernize your data platform faster with cloud-based ETL",
      Description:
        "The Mactores ETL Migration Accelerator uses automation for faster assessment, migration, and modernization of your data platform",
      ButtonText: "View",
      URL: "How to modernize your data platform faster with cloud-based ETL",
      Links:
        "https://mactores.com/blog/how-to-modernize-your-data-platform-faster-with-cloud-based-etl",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Cloud%20Based%20ETL.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Flipboard Partners with Mactores to Migrate High Volume Cloudera HBase Data Platform to a Fully-Managed Amazon EMR Service",
      Description:
        "Mactores Helped Flipboard Partners Migrate Their High Volume Cloudera HBase Data Platform to a Fully-Managed Amazon EMR Service. Here's how!",
      ButtonText: "View",
      URL: "Flipboard Partners with Mactores to Migrate High Volume Cloudera HBase Data Platform to a Fully-Managed Amazon EMR Service",
      Links:
        "https://mactores.com/blog/flipboard-partners-with-mactores-to-migrate-high-volume-cloudera-hbase-data-platform-to-a-fully-managed-amazon-emr-service",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Flipboard.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Lower TCO, Boost Query Performance: Run Hive on Spark in Amazon EMR",
      Description:
        "Learn how to leverage Apache Hive on Apache Spark for large queries (>10TB) using transient Amazon EMR clusters with EC2 Spot Instances.",
      ButtonText: "View",
      URL: "Lower TCO, Boost Query Performance: Run Hive on Spark in Amazon EMR",
      Links:
        "https://mactores.com/blog/lower-tco-boost-query-performance-run-hive-on-spark-in-amazon-emr",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Lower%20TCO.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Optimizing Presto SQL on Amazon EMR to Deliver Faster Query Processing",
      Description:
        "Post details 3 migrations, results, and architecture. Challenge solved: delivering data platform for processing petabytes with consistent performance.",
      ButtonText: "View",
      URL: "Optimizing Presto SQL on Amazon EMR to Deliver Faster Query Processing",
      Links:
        "https://mactores.com/blog/optimizing-presto-sql-on-amazon-emr-to-deliver-faster-query-processing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Hadoop%20EMR%20Presto%20SQL.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Mactores Achieves Amazon EMR Service Delivery Partner Status",
      Description:
        "Mactores' Amazon EMR service delivery designation enables end-to-end data platform modernization, and customers to transform and gain business insights.",
      ButtonText: "View",
      URL: "Mactores Achieves Amazon EMR Service Delivery Partner Status",
      Links:
        "https://mactores.com/blog/mactores-achieves-amazon-emr-service-delivery-partner-status",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon%20EMR.jpg",
    },
    {
      AssetType: "Video",
      Industry: "Manufacturing",
      Title: "Manufacturing 4.0: Most cost-effective data platform anywhere",
      Description:
        "Mactores in collaboration with AWS has helped manufacturing companies like HP, Synaptics, and Katerra alleviate cost and technology challenges by transforming their Data Analytics Platform",
      ButtonText: "View",
      URL: "Manufacturing 4.0: Most cost-effective data platform anywhere - YouTube",
      Links: "https://www.youtube.com/watch?v=elHpqzNEZtc",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/DocuSign%20Accelerates%20DataOps.jpg",
    },
    {
      AssetType: "Ebook",
      Industry: "Internet Software",
      Title: "Accelerate your Migration and Modernize on AWS with Mactores",
      Description: "A guide to migration and modernizing your workloads on AWS",
      ButtonText: "Download",
      URL: "Accelerate your Migration and Modernize on AWS with Mactores",
      Links:
        "https://mactores.com/hubfs/Accelerate%20your%20%20Migration%20and%20%20Modernize%20on%20AWS%20%20with%20Mactores%20-%20Ebook/%5BeBook%5D%20Accelerate%20your%20Migration%20and%20Modernize%20on%20AWS%20with%20Mactores.pdf?hsLang=en",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Accelerate_Migrate_Modernize.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title:
        "Lower TCO and Increase Query Performance by Running Hive on Spark in Amazon EMR",
      Description:
        "Learn how to leverage Apache Hive on Apache Spark for large queries (>10TB) using transient Amazon EMR clusters with EC2 Spot Instances.",
      ButtonText: "View",
      URL: "Lower TCO and Increase Query Performance by Running Hive on Spark in Amazon EMR",
      Links:
        "https://aws.amazon.com/blogs/apn/lower-tco-and-increase-query-performance-by-running-hive-on-spark-in-amazon-emr/",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Lower_TCO.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title:
        "Optimizing Presto SQL on Amazon EMR to Deliver Faster Query Processing",
      Description:
        "Post details 3 migrations, results, and architecture. Challenge solved: delivering data platform for processing petabytes with consistent performance.",
      ButtonText: "View",
      URL: "Optimizing Presto SQL on Amazon EMR to Deliver Faster Query Processing",
      Links:
        "https://aws.amazon.com/blogs/apn/optimizing-presto-sql-on-amazon-emr-to-deliver-faster-query-processing/",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Optimising_Presto_SQL.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of Amazon EMR with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance big data processing and analytics capabilities with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon EMR",
      ButtonText: "View",
      URL: "Amazon EMR Email",
      Links:
        "https://docs.google.com/document/d/1bHhe_oM27X787XomkRzB5oLiJsPDJZASKv9xK60BrkU/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20EMR.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Databricks to Amazon EMR: Operationalize Data Science on AWS With MLOPS",
      Description:
        "Explore transitioning from Databricks to Amazon EMR for streamlined MLOps on AWS. Discover key differences & migration strategies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/databricks-to-amazon-emr-operationalize-data-science-on-aws-with-mlops",
      Links:
        "https://mactores.com/blog/databricks-to-amazon-emr-operationalize-data-science-on-aws-with-mlops",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Databricks_EMR_MLOPS.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Databricks to Amazon EMR: Operationalize Data Science on AWS with DataOps",
      Description:
        "Transition from Databricks to Amazon EMR for robust DataOps on AWS. Seamlessly migrate workflows with expert guidance. Contact us now!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/databricks-to-amazon-emr-operationalize-data-science-on-aws-with-dataops",
      Links:
        "https://mactores.com/blog/databricks-to-amazon-emr-operationalize-data-science-on-aws-with-dataops",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Databricks_EMR_DataOps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Databricks to Amazon EMR: Build Serverless Enterprise Data Science Platform on AWS",
      Description:
        "Transition from Databricks to Amazon EMR for a serverless data science platform on AWS. Comparative analysis & step-by-step guide provided",
      ButtonText: "View",
      URL: "https://mactores.com/blog/databricks-to-amazon-emr-build-serverless-enterprise-data-science-platform-on-aws",
      Links:
        "https://mactores.com/blog/databricks-to-amazon-emr-build-serverless-enterprise-data-science-platform-on-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Databricks_EMR_Serverless.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Apache BEAM on Amazon EMR",
      Description:
        "Explore Apache Beam on Amazon EMR, offering seamless data flow, flexibility, and cost-effectiveness for big data workloads.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/apache-beam-on-amazon-emr",
      Links: "https://mactores.com/blog/apache-beam-on-amazon-emr",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Apache_Beam_EMR.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Migrate Databricks to Amazon EMR: Enterprise Data Science on AWS",
      Description:
        "Unlock the full potential of AWS with our guide to migrating Databricks to Amazon EMR for advanced enterprise data science.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/migrate-databricks-to-amazon-emr-enterprise-data-science-on-aws",
      Links:
        "https://mactores.com/blog/migrate-databricks-to-amazon-emr-enterprise-data-science-on-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Databricks_Data_Science.jpg",
    },
  ],
  GenAI: [
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title:
        "Unlock the Power of Amazon Bedrock and Jumpstart with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to deploy Generative AI Models with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon Bedrock and SageMaker Jumpstart",
      ButtonText: "View",
      URL: "Amazon GenAI Email",
      Links:
        "https://docs.google.com/document/d/1cMtji5vnzYVeRczQ9KX5TSxQhP2MazxtkaCA7t8vsNQ/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Gen%20AI.jpg",
    },
    {
      AssetType: "Solution Brief",
      Industry: "Internet Software",
      Title: "Revolutionize Data Insights with ChatGPT",
      Description:
        "Improve productivity by 5x using ChatGPT™ Integration with Mactores™ Aedeon® Data Lake on AWS, Amazon Redshift, and Snowflake",
      ButtonText: "Download",
      URL: "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      Links:
        "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/ChatGPT_Integration.jpg",
    },
    {
      AssetType: "Solution Brief",
      Industry: "Internet Software",
      Title:
        "How Mactores Helps You Leverage Generative AI at Scale to Realize Business Objectives Faster",
      Description: "Learn about the the Mactores Generative AI Framework",
      ButtonText: "Download",
      URL: "https://2205669.fs1.hubspotusercontent-na1.net/hubfs/2205669/GenAI%20Solution%20Page/GenAI_Solution_Brief.pdf",
      Links:
        "https://2205669.fs1.hubspotusercontent-na1.net/hubfs/2205669/GenAI%20Solution%20Page/GenAI_Solution_Brief.pdf",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Solution%20Brief/GenAI_Solution%20Brief.jpg",
    },
    {
      AssetType: "E-Book",
      Industry: "Internet Software",
      Title: "Driving Business Value With Generative AI",
      Description: "Mactores: The Proven Framework for Generative AI Adoption",
      ButtonText: "Download",
      URL: "https://2205669.fs1.hubspotusercontent-na1.net/hubfs/2205669/GenAI%20Solution%20Page/GenAI_Ebook.pdf",
      Links:
        "https://2205669.fs1.hubspotusercontent-na1.net/hubfs/2205669/GenAI%20Solution%20Page/GenAI_Ebook.pdf",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook%2c%20E-Book%2c%20Video%20Images/GenAI_E-Book.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Optimizing Production Lines with GenAI: A Manufacturing Game-Changer",
      Description:
        "Discover the transformative power of GenAI in manufacturing. From optimized production to predictive maintenance, unlock efficiency with Mactores today!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-production-lines-with-genai-a-manufacturing-game-changer",
      Links:
        "https://mactores.com/blog/optimizing-production-lines-with-genai-a-manufacturing-game-changer",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Optimizing_Production_Lines.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Unlocking the Power of GenAI: Mactores' Proof of Value Approach",
      Description:
        "Unlock generative AI's power with Mactores' Proof of Value. Fast, efficient, and transformative implementation!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/unlocking-the-power-of-gen-ai-mactores-proof-of-value-approach",
      Links:
        "https://mactores.com/blog/unlocking-the-power-of-gen-ai-mactores-proof-of-value-approach",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Unlocking_Power_GenAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "GenAI and Cloud Security: Fortifying Protection for Genomic Data",
      Description:
        "GenAI and Cloud Security fortify genomic data protection. Learn more about encryption, access controls & AWS services and how they ensure confidentiality.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/genai-and-cloud-security-fortifying-protection-for-genomic-data",
      Links:
        "https://mactores.com/blog/genai-and-cloud-security-fortifying-protection-for-genomic-data",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Cloud_Security.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Transformative Power of GenAI: Business Use Cases Across Various Units",
      Description:
        "Unlocking Business Potential with Generative AI: Revolutionizing Sales, Operations, HR, and More. Discover the Transformative Power!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/transformative-power-of-genai-business-use-cases-across-various-units",
      Links:
        "https://mactores.com/blog/transformative-power-of-genai-business-use-cases-across-various-units",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Transformative_Power_GenAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "GenAI - Revolutionizing Healthcare Through Cloud-Powered Genomics",
      Description:
        "Discover cloud-powered genomics and GenAI revolutionizing healthcare with data privacy, predictive analysis, and reduced errors. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/genai-revolutionizing-healthcare-through-cloud-powered-genomics",
      Links:
        "https://mactores.com/blog/genai-revolutionizing-healthcare-through-cloud-powered-genomics",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Revolutionizing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Future-Proof Your Database: Generative AI for Oracle Migration to AWS",
      Description:
        "Future-proof your Oracle-to-AWS migration with Generative AI! Automate tasks, improve accuracy, optimize costs, and enhance security. Learn more now!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/future-proof-your-database-generative-ai-for-oracle-migration-to-aws",
      Links:
        "https://mactores.com/blog/future-proof-your-database-generative-ai-for-oracle-migration-to-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenerativeAI_Oracle.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Cloud Migration: Oracle & Generative AI Integration on AWS",
      Description:
        "Optimize Oracle migration to AWS with Generative AI. Enhance efficiency, reduce costs, and unlock innovation for your cloud journey",
      ButtonText: "View",
      URL: "https://mactores.com/blog/cloud-migration-oracle-generative-ai-integration-on-aws",
      Links:
        "https://mactores.com/blog/cloud-migration-oracle-generative-ai-integration-on-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Oracle_Generative_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Generative AI Real-World Use Case: Transforming Manufacturing",
      Description:
        "Unlock manufacturing potential with generative AI: from design revolution to sustainable innovation. Explore the future now!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/generative-ai-real-world-use-case-transforming-manufacturing",
      Links:
        "https://mactores.com/blog/generative-ai-real-world-use-case-transforming-manufacturing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenerativeAI_Transforming_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Navigating the Generative AI Landscape to transform your business",
      Description:
        "Unlock business success with generative AI: Learn what it is, key considerations, and 4 impactful use cases for innovation and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/navigating-the-generative-ai-landscape-to-transform-your-business",
      Links:
        "https://mactores.com/blog/navigating-the-generative-ai-landscape-to-transform-your-business",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Navigating_GenerativeAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What does Mactores do in Generative AI?",
      Description:
        "Mactores: Navigating the World of Generative AI | Expertise, Customized Solutions, Ethical Compliance, and Ongoing Support",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-does-mactores-do-in-generative-ai",
      Links: "https://mactores.com/blog/what-does-mactores-do-in-generative-ai",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Mactores_GenerativeAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Unleashing Imagination: How Generative AI Can Transform Industries",
      Description:
        "Generative AI, a powerhouse, is reshaping art, music, fashion, content, and prediction across industries, heralding an era of innovation and challenges.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/unleashing-imagination-how-generative-ai-can-transform-industries",
      Links:
        "https://mactores.com/blog/unleashing-imagination-how-generative-ai-can-transform-industries",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Unleashing_GenerativeAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "The Changing Role of CTOs in the Age of AI",
      Description:
        "Discover how AI is reshaping the role of CTOs, from strategic leadership to leveraging AI for competitive advantage.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/the-changing-role-of-ctos-in-the-age-of-ai",
      Links:
        "https://mactores.com/blog/the-changing-role-of-ctos-in-the-age-of-ai",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Changing_Role_CTO.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "7 Tech Trends Business Leaders Should Watch Out For In 2024",
      Description:
        "Discover the top tech trends for 2024, from generative AI to quantum computing, and learn how they can shape your organization's strategies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/7-tech-trends-business-leaders-should-watch-out-for-in-2024",
      Links:
        "https://mactores.com/blog/7-tech-trends-business-leaders-should-watch-out-for-in-2024",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/7_Tech_Trends.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Aurora GenAI: Illuminating the Path to Breakthroughs in Life Sciences",
      Description:
        "Discover Aurora GenAI's transformative role in life sciences. Explore AI applications from drug discovery to personalized medicine.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/aurora-genai-illuminating-the-path-to-breakthroughs-in-life-sciences",
      Links:
        "https://mactores.com/blog/aurora-genai-illuminating-the-path-to-breakthroughs-in-life-sciences",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Aurora_GenAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "AWS GenAI: The Financial Sector's Gateway to AI Innovation",
      Description:
        "Unlock innovation in finance with AWS GenAI. Enhance data analysis, risk management, and customer experience.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/aws-genai-the-financial-sectors-gateway-to-ai-innovation",
      Links:
        "https://mactores.com/blog/aws-genai-the-financial-sectors-gateway-to-ai-innovation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_GenAI_Financial.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "What Is GenAI and How It's Transforming the Entertainment Industry",
      Description:
        "Explore GenAI's transformative potential in entertainment, from streamlining content creation to fostering audience engagement.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-is-genai-and-how-its-transforming-the-entertainment-industry",
      Links:
        "https://mactores.com/blog/what-is-genai-and-how-its-transforming-the-entertainment-industry",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_GenAI_Entertainment.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "AI in Healthcare: Transforming Patient Care with GenAI Bots",
      Description:
        "Discover how GenAI bots are reshaping patient care in healthcare with personalized support and enhanced efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/ai-in-healthcare-transforming-patient-care-with-genai-bots",
      Links:
        "https://mactores.com/blog/ai-in-healthcare-transforming-patient-care-with-genai-bots",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_GenAI_Healthcare.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Investment in AI Stock: Financial Implications and Insights",
      Description:
        "Explore the financial implications of investing in AI stocks. Gain insights into market trends, growth potential, and risk analysis.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/investment-in-ai-stock-financial-implications-and-insights",
      Links:
        "https://mactores.com/blog/investment-in-ai-stock-financial-implications-and-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Investment_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "GenAI in Fintech: Opportunities and Use Cases in 2024",
      Description:
        "Explore the transformative potential of Generative AI in Fintech. Enhance decision-making, automate processes, and personalize services for growth.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/genai-in-fintech-opportunities-and-use-cases-in-2024",
      Links:
        "https://mactores.com/blog/genai-in-fintech-opportunities-and-use-cases-in-2024",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Fintech.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "GenAI vs. Traditional AI: Unique Differences in Media & Entertainment",
      Description:
        "Discover the creative distinctions between GenAI & Traditional AI in Media & Entertainment. Explore their roles, impact, and future trends.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/genai-vs-traditional-ai-unique-differences-in-media-entertainment",
      Links:
        "https://mactores.com/blog/genai-vs-traditional-ai-unique-differences-in-media-entertainment",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_TraditionalAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "GenAI Studio: Reinventing Content Creation in Media & Entertainment",
      Description:
        "Discover the impact of GenAI in Media & Entertainment: streamlining production, enhancing engagement, and unlocking new revenue streams.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/genai-studio-reinventing-content-creation-in-media-entertainment",
      Links:
        "https://mactores.com/blog/genai-studio-reinventing-content-creation-in-media-entertainment",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Entertainment.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Advancements in Neuroscience: GenAI's Role in Hallucinations",
      Description:
        "Learn Generative Artificial Intelligence (GenAI) transforms hallucination research from simulating brain activity to accelerating treatment development.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/advancements-in-neuroscience-genais-role-in-hallucinations",
      Links:
        "https://mactores.com/blog/advancements-in-neuroscience-genais-role-in-hallucinations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Neuroscience.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Transforming Data into Decisions: The Power of GenAI Images",
      Description:
        "Unlock actionable insights with GenAI images - revolutionizing data visualization for informed decision-making across industries.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/transforming-data-into-decisions-the-power-of-genai-images",
      Links:
        "https://mactores.com/blog/transforming-data-into-decisions-the-power-of-genai-images",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Decisions.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Branding for the Future: The GenAI Logos Significance in the Industry",
      Description:
        "Experience the future of branding with GenAI logos - revolutionizing design, perception, and consumer engagement.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/branding-for-the-future-the-genai-logos-significance-in-the-industry",
      Links:
        "https://mactores.com/blog/branding-for-the-future-the-genai-logos-significance-in-the-industry",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Branding.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "The Art of Gaming: How GenAI Transforms In-Game Visuals",
      Description:
        "Experience the future of gaming visuals with GenAI - revolutionizing landscapes, characters, and immersion.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/the-art-of-gaming-how-genai-transforms-in-game-visuals",
      Links:
        "https://mactores.com/blog/the-art-of-gaming-how-genai-transforms-in-game-visuals",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Gaming.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Innovative GenAI Trends: Shaping the Future of MedTech",
      Description:
        "Discover how GenAI is revolutionizing MedTech, transforming healthcare with precision medicine, wearables, and AI-driven diagnostics. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/innovative-genai-trends-shaping-the-future-of-medtech",
      Links:
        "https://mactores.com/blog/innovative-genai-trends-shaping-the-future-of-medtech",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Trends.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Efficiency and Precision: GenAI Tools Redefining Healthcare Research",
      Description:
        "Explore how GenAI tools are revolutionizing healthcare and research with enhanced precision, personalized treatment, and accelerated discoveries.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/efficiency-and-precision-genai-tools-redefining-healthcare-research",
      Links:
        "https://mactores.com/blog/efficiency-and-precision-genai-tools-redefining-healthcare-research",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Efficiency.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "Interactive Dreams: GenAI Image Generators and the Gaming Experience",
      Description:
        "Explore how GenAI image generators transform gaming with dynamic, adaptive worlds and personalized storytelling, redefining the player experience.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/interactive-dreams-genai-image-generators-and-the-gaming-experience",
      Links:
        "https://mactores.com/blog/interactive-dreams-genai-image-generators-and-the-gaming-experience",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Interactive.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "AI and FinTech: Unveiling GenAI's Role in Financial Innovations",
      Description:
        "Discover how Generative AI revolutionizes FinTech with personalized financial planning, enhanced fraud detection, empathetic chatbots, and more.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/ai-and-fintech-unveiling-genais-role-in-financial-innovations",
      Links:
        "https://mactores.com/blog/ai-and-fintech-unveiling-genais-role-in-financial-innovations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_FinTech_Innovation.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Future Proof Talent Strategies: Leverage Generative AI for Success",
      Description:
        "Discover how Generative AI can future-proof your talent strategies with streamlined recruitment, personalized training, and enhanced retention for success.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/future-proof-talent-strategies-leverage-generative-ai-for-success",
      Links:
        "https://mactores.com/blog/future-proof-talent-strategies-leverage-generative-ai-for-success",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Futureproof.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Manufacturing in a Secure World: GenAI Security Solutions and Beyond",
      Description:
        "Explore how Generative AI enhances security in manufacturing with real-time data analysis, predictive maintenance, and advanced threat detection.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/manufacturing-in-a-secure-world-genai-security-solutions-and-beyond",
      Links:
        "https://mactores.com/blog/manufacturing-in-a-secure-world-genai-security-solutions-and-beyond",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Secure_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Smart Marketing with GenAI: Targeted Campaigns and Creative Content",
      Description:
        "Leverage Generative AI in marketing for targeted campaigns and creative content to boost engagement and ROI.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/smart-marketing-with-genai-targeted-campaigns-and-creative-content",
      Links:
        "https://mactores.com/blog/smart-marketing-with-genai-targeted-campaigns-and-creative-content",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Smart_Marketing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Generative AI: Reshaping Automotive Design and Engineering",
      Description:
        "Explore how Generative AI revolutionizes automotive design and engineering, enhancing creativity, efficiency, and technological advancements.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/generative-ai-reshaping-automotive-design-and-engineering",
      Links:
        "https://mactores.com/blog/generative-ai-reshaping-automotive-design-and-engineering",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/GenAI_Automotive_Design.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Manufacturing Revolution: Real-World GenAI Use Cases & Success Stories",
      Description:
        "Explore how GenAI transforms manufacturing with real-world use cases, enhancing efficiency, reducing costs, and driving innovation across industries.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/manufacturing-revolution-real-world-genai-use-cases-success-stories",
      Links:
        "https://mactores.com/blog/manufacturing-revolution-real-world-genai-use-cases-success-stories",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Manufacturing_Revolution_GenAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How to Build a Strong Data Foundation for Generative AI Success",
      Description:
        "Learn how to build a strong data foundation for generative AI success with Mactores’ proven approach to automation, governance, and data accuracy.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-to-build-a-strong-data-foundation-for-generative-ai-success",
      Links:
        "https://mactores.com/blog/how-to-build-a-strong-data-foundation-for-generative-ai-success",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Foundation_Generative_AI.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Generative AI to Production",
      Description:
        "Accelerate your Generative AI deployments on AWS with Mactores' comprehensive service. We leverage AWS services and the Mactores AI platform to seamlessly transition your AI models from development to robust production environments, ensuring scalability and reliability.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-5psy6fviotqpm?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-5psy6fviotqpm?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Generative%20AI%20to%20Production.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Generative AI Proof of Value (PoV)",
      Description:
        "The Mactores Generative AI Proof of Value (PoV) is a focused workshop designed to validate the feasibility and business impact of selected AI use cases within 4 to 6 weeks.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-hme6ma442bk34?sr=0-4&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-hme6ma442bk34?sr=0-4&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Generative%20AI%20Proof%20of%20Value%20(PoV).jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI-based Mactores Database CoPilot",
      Description:
        "The Generative AI-based Mactores Database CoPilot Tool simplifies database management and migration on Amazon RDS. Leverage AI to optimize SQL queries, design efficient schemas, generate ER diagrams, and automate tasks.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-2pgqfbxwsikmc?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-2pgqfbxwsikmc?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI-based%20Mactores%20Database%20CoPilot.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores GenAI Strategy Workshop ",
      Description:
        "Mactores offers a GenAI strategy workshop that develops a comprehensive AI strategy aligned with your business goals. We help you define your roadmap, resources, and success metrics to ensure a smooth and successful AI implementation.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-a24zwukgbrjcq?sr=0-9&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-a24zwukgbrjcq?sr=0-9&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20GenAI%20Strategy%20Workshop%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "GenAI Knowledge Base Solution",
      Description:
        "Streamline your AI initiatives with a centralized knowledge base built and maintained by Mactores. Our AI Knowledge Base service develops and maintains up-to-date documentation, tutorials, and FAQs tailored to your specific AI use cases.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-xpexftq3pzsh2?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-xpexftq3pzsh2?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/GenAI%20Knowledge%20Base%20Solution.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Generative AI Engineering Immersion Day",
      Description:
        "Mactores' Engineering Immersion Day: AWS GenAI Dive deep into AWS GenAI with our hands-on workshop. We tailor the day to your needs",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Generative%20AI%20Engineering%20Immersion%20Day.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI Innovation Engine",
      Description:
        "Accelerate your AI initiatives on AWS with our Generative AI Innovation Engine. Our dedicated team explores new AI frontiers, experiments with AWS GenAI services like Amazon Bedrock, and seamlessly integrates breakthroughs into your AWS cloud environment.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-m3vd4q7u46tq4?sr=0-28&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-m3vd4q7u46tq4?sr=0-28&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20Innovation%20Engine.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "GenAI Strategy Immersion Workshop",
      Description:
        "Develop a winning Generative AI strategy for AWS with our immersive workshop. In 3-6 hours, we'll help you identify, prioritize, and plan high-impact AI use cases that align with your business goals and leverage the power of AWS services like Amazon Bedrock.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-7ozhey74oivb2?sr=0-29&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-7ozhey74oivb2?sr=0-29&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/GenAI%20Strategy%20Immersion%20Workshop.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI Discovery Call ",
      Description:
        "Explore the potential of Generative AI on AWS with our tailored Discovery Call. We'll delve deep into your business challenges and goals, exploring how AWS services like Amazon Bedrock can optimize your workflows and drive innovation.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-3cyqczlxtzl2w?sr=0-30&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-3cyqczlxtzl2w?sr=0-30&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20Discovery%20Call%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Custom AI Solution for FinTech",
      Description:
        "Mactores' Custom AI solutions for FinTech empower financial institutions to tackle challenges in fraud detection, compliance, and customer engagement through advanced machine learning, big data processing, and predictive analytics.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Custom%20AI%20Solution%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "PCI-Compliant Card Data Environments With Analytics For FinTech",
      Description:
        "Mactores offers specialized FinTech solutions designed to harness the power of AI, machine learning, blockchain, and cloud computing to transform financial operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-yvv6lg4r5izxo?sr=0-14&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-yvv6lg4r5izxo?sr=0-14&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/PCI-Compliant%20Card%20Data%20Environments%20With%20Analytics%20For%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "AI-Driven Product Development For Internet Software",
      Description:
        "Accelerate product development with Mactores' AI-Driven Product Development for Internet Software. Harnessing Data Analytics, Generative AI, and Machine Learning, we empower software companies to innovate faster, optimize operations, and scale efficiently.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/AI-Driven%20Product%20Development%20For%20Internet%20Software%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "AI-Powered DevOps Pipelines For Internet Software",
      Description:
        "Mactores' AI-Powered DevOps Pipelines optimize software delivery on AWS. Our intelligent CI/CD workflows integrate with AWS services like AWS CodePipeline, AWS Lambda, Amazon S3, and Amazon CloudWatch to enable faster releases, automated security, and predictive scaling.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-pkwidr6c2txbg?sr=0-20&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-pkwidr6c2txbg?sr=0-20&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/AI-Powered%20DevOps%20Pipelines%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Cloud Optimization Services For Internet Software",
      Description:
        "We leverage data analytics, generative AI, and machine learning to enhance performance, reduce costs, and improve scalability.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-oebtrrmce4tak?sr=0-20&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-oebtrrmce4tak?sr=0-20&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Cloud%20Optimization%20Services%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI for Design For Internet Software",
      Description:
        "Mactores automates software design, creating adaptive interfaces, rapid prototypes, and personalized user experiences. Reduce design cycles and ensure consistency across applications with AI-powered automation.",
      ButtonText: "View",
      Links:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20for%20Design%20For%20Internet%20Software.jpg",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20for%20Design%20For%20Internet%20Software.jpg",
    },
  ],
  rdsList: [
    {
      AssetType: "Case studies",
      Industry: "Financial Services",
      Title: "Blackhawk Network",
      Description:
        "Branded payments leader retired technical debt by migrating from Microsoft SQL Server to Amazon Aurora PostgreSQL",
      ButtonText: "Download",
      URL: "Branded Payments Leader Retired Technical Debt",
      Links:
        "https://mactores.com/branded-payments-leader-retired-technical-debt",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Blackhawk_Network_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Manufacturing",
      Title: "Synaptics",
      Description:
        "Mactores Automation Accelerators drive successful migration from Oracle to Amazon RDS PostgreSQL for Silicon Valley Manufacturer",
      ButtonText: "Download",
      URL: "Mactores Automates Oracle to Amazon RDS Migration for Synaptics",
      Links:
        "https://mactores.com/mactores-automates-oracle-to-amazon-rds-migration-for-synaptics",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Synaptics_Migration_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Oracle to PostgreSQL on RDS Aurora Migration Assessment",
      Description:
        "Migrating a database from Oracle to PostgreSQL can be a daunting task, but with the help of Mactores DB assessment, the process can be made much smoother. Our assessment involves a thorough evaluation and planning of the migration, ensuring that all aspects are considered. The duration of the assessment is typically between 2 to 4 weeks",
      ButtonText: "View",
      URL: "Oracle to PostgreSQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tuul7f2ppjoxy?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Oracle%20to%20PostgreSQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Database Modernization and Cost Saving Workshop",
      Description:
        "Database modernization is a crucial process for organizations looking to improve their data management practices. Assessment Workshop is a comprehensive and value-driven solution for modernizing and optimizing your databases. We'll align your use cases to the right databases such as, but not limited to; Amazon Aurora, Amazon DynamoDB, Amazon ElastiCache, Amazon Neptune, Amazon Timestream, and Amazon Relational Database Service",
      ButtonText: "View",
      URL: "Database Modernization and Cost Saving Workshop",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-bfeagrzaqnwmu?sr=0-6&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Database%20Modernization%20and%20Cost%20Saving%20Workshop.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "MySQL on RDS to MySQL on RDS Aurora Migration Assessment",
      Description:
        "MySQL to MySQL on RDS Aurora Migration Assessment accelerates the discovery process with automated tools that collect information about your databases and connected applications",
      ButtonText: "View",
      URL: "MySQL on RDS to MySQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-s3ct5lm5rp4ow?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/MySQL%20on%20RDS%20to%20MySQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "MSSQL to MySQL on RDS Aurora Migration Assessment",
      Description:
        "MSSQL to MySQL on RDS Aurora Migration Assessment accelerates the discovery process with automated tools that collect information about your databases and connected applications",
      ButtonText: "View",
      URL: "MSSQL to MySQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-otduzzdezdctk?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/MSSQL%20to%20MySQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Oracle to MySQL on RDS Aurora Migration Assessment",
      Description:
        "Oracle to MySQL on RDS Aurora Migration Assessment accelerates the discovery process with automated tools that collect information about your databases and connected applications",
      ButtonText: "View",
      URL: "Oracle to MySQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-ksqyplozv4jjk?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Oracle%20to%20MySQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "MSSQL to PostgreSQL on RDS Aurora Migration Assessment",
      Description:
        "MSSQL to PostgreSQL on RDS Aurora Migration Assessment accelerates the discovery process with automated tools that collect information about your databases and connected applications",
      ButtonText: "View",
      URL: "MSSQL to PostgreSQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-a3743h52q2k3g?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/MSSQL%20to%20PostgreSQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title:
        "PostgreSQL on RDS to PostgreSQL on RDS Aurora Migration Assessment",
      Description:
        "PostgreSQL to PostgreSQL on RDS Aurora Migration Assessment accelerates the discovery process with automated tools that collect information about your databases and connected applications",
      ButtonText: "View",
      URL: "PostgreSQL on RDS to PostgreSQL on RDS Aurora Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-g4jzsya75fouy?sr=0-11&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/PostgreSQL%20on%20RDS%20to%20PostgreSQL%20on%20RDS%20Aurora%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Migrating from Oracle database to Amazon RDS PostgreSQL using AWS DMS",
      Description:
        "Discover how Synaptics engineering team optimized Atlassian JIRA & Confluence on AWS by migrating Oracle 11g to Amazon Aurora Postgres SQL.",
      ButtonText: "View",
      URL: "Migrating from Oracle database to Amazon RDS PostgreSQL using AWS DMS",
      Links:
        "https://mactores.com/blog/migrating-oracle-database-to-amazon-rds-postgresql-using-aws-dms",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Oracle%20to%20RDS.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of Amazon RDS with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance database management and scalability with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon RDS",
      ButtonText: "View",
      URL: "Amazon RDS Email",
      Links:
        "https://docs.google.com/document/d/19UTyc-MLSZQYCXSXyeyMbHUKCxPIfqyD5dvEQ1R_r-0/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20RDS.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Harnessing AI and Oracle Licensing Optimization on AWS",
      Description:
        "Unlock innovation & cut costs in Oracle migration to AWS! Explore synergy of AWS OLA & Generative AI for optimal database management & growth strategies",
      ButtonText: "View",
      URL: "https://mactores.com/blog/harnessing-ai-and-oracle-licensing-optimization-on-aws",
      Links:
        "https://mactores.com/blog/harnessing-ai-and-oracle-licensing-optimization-on-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Harnessing_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Cloud Migration: Oracle & Generative AI Integration on AWS",
      Description:
        "Optimize Oracle migration to AWS with Generative AI. Enhance efficiency, reduce costs, and unlock innovation for your cloud journey",
      ButtonText: "View",
      URL: "https://mactores.com/blog/cloud-migration-oracle-generative-ai-integration-on-aws",
      Links:
        "https://mactores.com/blog/cloud-migration-oracle-generative-ai-integration-on-aws",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Oracle_Generative_AI-1.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Step-By-Step Guide to a Successful Database Migration",
      Description:
        "Unlock the secrets to a seamless database migration with our comprehensive guide tailored for tech leaders.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/step-by-step-guide-to-a-successful-database-migration",
      Links:
        "https://mactores.com/blog/step-by-step-guide-to-a-successful-database-migration",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Step_By_Step.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "8 Techniques to Minimize Downtime During Database Migration",
      Description:
        "Learn how to minimize downtime and ensure data consistency during database migration to mitigate risks and ensure a seamless transition.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/8-techniques-to-minimize-downtime-during-database-migration",
      Links:
        "https://mactores.com/blog/8-techniques-to-minimize-downtime-during-database-migration",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/8_Techniques.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Right Use Cases For Relational DB, Document DB, Graph DB, Time Series DB, and In-Memory DB",
      Description:
        "Learn how to choose the perfect database for your business needs: Relational, Document, Graph, Time Series, or In-Memory. Make informed decisions here!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/right-use-cases-for-relational-db-document-db-graph-db-time-series-db-and-in-memory-db",
      Links:
        "https://mactores.com/blog/right-use-cases-for-relational-db-document-db-graph-db-time-series-db-and-in-memory-db",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Relational_DB_Document_DB.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Migrating Stored Procedures, Triggers, and Other Database Objects",
      Description:
        "Master the art of database migration! Learn best practices for seamlessly transferring stored procedures, triggers, and more!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/migrating-stored-procedures-triggers-and-other-database-objects",
      Links:
        "https://mactores.com/blog/migrating-stored-procedures-triggers-and-other-database-objects",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Stored_Procedures.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Aurora GenAI: Illuminating the Path to Breakthroughs in Life Sciences",
      Description:
        "Discover Aurora GenAI's transformative role in life sciences. Explore AI applications from drug discovery to personalized medicine.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/aurora-genai-illuminating-the-path-to-breakthroughs-in-life-sciences",
      Links:
        "https://mactores.com/blog/aurora-genai-illuminating-the-path-to-breakthroughs-in-life-sciences",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Aurora_GenAI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "8 Ways for a Sustainable Database Migration",
      Description:
        "Discover 8 sustainable database migration strategies to reduce your carbon footprint while maintaining productivity and staying within budget.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/8-ways-for-a-sustainable-database-migration",
      Links:
        "https://mactores.com/blog/8-ways-for-a-sustainable-database-migration",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/8_Database_Migration.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI-based Mactores Database CoPilot",
      Description:
        "The Generative AI-based Mactores Database CoPilot Tool simplifies database management and migration on Amazon RDS. Leverage AI to optimize SQL queries, design efficient schemas, generate ER diagrams, and automate tasks.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-2pgqfbxwsikmc?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-2pgqfbxwsikmc?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI-based%20Mactores%20Database%20CoPilot.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Technology Integration and Optimization for FinTech",
      Description:
        "Mactores empowers FinTech firms to embrace digital transformation confidently by integrating AI, blockchain, and cloud technologies.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Technology%20Integration%20and%20Optimization%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Automated Database Migration (GenAI) For Internet Software",
      Description:
        "Mactores’ Automated Database Migration with Generative AI enables Internet and Software companies to seamlessly transition from legacy databases to cloud-native environments with minimal disruption.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Automated%20Database%20Migration%20(GenAI)%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Platform Migration For Internet Software",
      Description:
        "Our Automated Data Platform Migration solution leverages AWS services like Amazon RDS, AWS Database Migration Service (DMS), Amazon Redshift, and AWS Glue to optimize migrations at scale.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Platform%20Migration%20For%20Internet%20Software.jpg",
    },
  ],
  mskList: [
    {
      AssetType: "Case studies",
      Industry: "Internet Software",
      Title: "Poshmark",
      Description: "E-Commerce Company Achieves Cloud Modernization   ",
      ButtonText: "Download",
      URL: "E-Commerce Company Achieves Cloud Modernization   ",
      Links:
        "https://mactores.com/e-commerce-company-achieves-cloud-modernization",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Poshmark_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Fully managed and scalable modernized data streaming platform",
      Description:
        "By driving best practices through design, strategy, and automation, Amazon MSK eliminates operational overhead, including the provisioning, configuration.",
      ButtonText: "View",
      URL: "Fully managed and scalable modernized data streaming platform",
      Links:
        "https://mactores.com/blog/fully-managed-and-scalable-modernized-data-streaming-platform",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Fully%20Managed%20Scalable.jpg",
    },
    {
      AssetType: "Video",
      Industry: "Gaming",
      Title: "Level-up the gamers' experience using Amazon MSK",
      Description:
        "Watch this tech talk to learn more about Amazon MSK, a fully managed service that makes it easy for gaming companies to build and run applications that use Apache Kafka to process streaming data. ",
      ButtonText: "View",
      URL: "Level-up the gamers' experience using Amazon MSK - YouTube",
      Links: "https://www.youtube.com/watch?v=HW5uwQaX_zE",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Level_Up_Gamers.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title:
        "Unlock the Power of Amazon MSK and Kinesis with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance real-time data streaming and event processing capabilities with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon MSK and Kinesis",
      ButtonText: "View",
      URL: "Amazon MSK/Kinesis Email",
      Links:
        "https://docs.google.com/document/d/1qND3lCOYWpeVCuDwdFwSj6A8_kK1Z-1-Tsapepk1BXc/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20MSK_%20Kinesis.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "The Power of Real-Time Analytics: Driving Business Growth",
      Description:
        "Explore the realm of real-time analytics, uncovering its significant advantages and the crucial role in guiding decision-making for organizations.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/the-power-of-real-time-analytics-driving-business-growth",
      Links:
        "https://mactores.com/blog/the-power-of-real-time-analytics-driving-business-growth",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Power_Real_Time.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Mastering Real-Time Data Processing with Amazon Managed Services",
      Description:
        "Uncover the power of real-time data processing with Amazon Managed Services. Learn from experts & real-world examples in our upcoming webinar!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/mastering-real-time-data-processing-with-amazon-managed-services",
      Links:
        "https://mactores.com/blog/mastering-real-time-data-processing-with-amazon-managed-services",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Mastering_Real_Time.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Real-World Applications of Real-Time Analytics: Success Stories",
      Description:
        "Discover real-time analytics success stories across industries. Learn how Apache Flink & AWS revolutionize data insights!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-world-applications-of-real-time-analytics-success-stories",
      Links:
        "https://mactores.com/blog/real-world-applications-of-real-time-analytics-success-stories",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Real_World_Applications.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Streamline Manufacturing Production with Amazon MSK in Real-Time",
      Description:
        "Discover how Amazon MSK optimizes manufacturing with real-time data, enhancing production efficiency, product quality, and supply chain visibility.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamline-manufacturing-production-with-amazon-msk-in-real-time",
      Links:
        "https://mactores.com/blog/streamline-manufacturing-production-with-amazon-msk-in-real-time",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Streamline_Manufacturing_Production_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Amazon MSK: Predictive Maintenance & Anomaly Detection in Manufacturing",
      Description:
        "Discover how Amazon MSK enhances predictive maintenance in manufacturing with real-time anomaly detection, reducing downtime and boosting efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-msk-predictive-maintenance-anomaly-detection-in-manufacturing",
      Links:
        "https://mactores.com/blog/amazon-msk-predictive-maintenance-anomaly-detection-in-manufacturing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Predictive_Maintenance_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Amazon MSK Boosts Real-Time Telehealth and Remote Care Monitoring",
      Description:
        "Enhance patient care with Amazon MSK by powering real-time monitoring, secure data processing, and seamless telehealth for remote healthcare solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-msk-boosts-real-time-telehealth-and-remote-care-monitoring",
      Links:
        "https://mactores.com/blog/amazon-msk-boosts-real-time-telehealth-and-remote-care-monitoring",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Telehealth_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Accelerating Drug Discovery with Amazon MSK for Real-Time Data",
      Description:
        "Accelerate drug discovery with Amazon MSK's real-time data analysis. Learn how pharmaceutical research can be faster, more accurate, and cost-effective.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/accelerating-drug-discovery-with-amazon-msk-for-real-time-data",
      Links:
        "https://mactores.com/blog/accelerating-drug-discovery-with-amazon-msk-for-real-time-data",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Drug_Discovery_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "HIPAA Compliance: Securing Patient Data with Amazon MSK’s Key Features",
      Description:
        "Ensure HIPAA compliance with Amazon MSK's encryption, access control, and monitoring features to safeguard patient data in healthcare organizations.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/hipaa-compliance-securing-patient-data-with-amazon-msks-key-features",
      Links:
        "https://mactores.com/blog/hipaa-compliance-securing-patient-data-with-amazon-msks-key-features",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/HIPAA_Compliance_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Optimize Supply Chains with Amazon MSK: Real-Time Data Insights",
      Description:
        "Optimize supply chains with Amazon MSK for real-time visibility, data-driven decisions, and improved efficiency in managing disruptions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimize-supply-chains-with-amazon-msk-real-time-data-insights",
      Links:
        "https://mactores.com/blog/optimize-supply-chains-with-amazon-msk-real-time-data-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Optimize_Supply_Chain_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Smart Factories with Amazon MSK: Powering IoT & Industrial Automation",
      Description:
        "Explore how Amazon MSK powers IoT and automation in smart factories with real-time data streaming and seamless integration, driving efficiency and growth.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/smart-factories-with-amazon-msk-powering-iot-industrial-automation",
      Links:
        "https://mactores.com/blog/smart-factories-with-amazon-msk-powering-iot-industrial-automation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/IoT_Industrial_Automation_Amazon_MSK.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Streamline Clinical Trials with Amazon MSK: Real-Time Data & Analysis",
      Description:
        "Streamline clinical trials with Amazon MSK, enabling real-time data collection and analysis for faster, accurate results and improved patient outcomes.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamline-clinical-trials-with-amazon-msk-real-time-data-analysis",
      Links:
        "https://mactores.com/blog/streamline-clinical-trials-with-amazon-msk-real-time-data-analysis",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Clinical_Trials.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Real-Time Fraud Detection for Financial Institutions with Amazon MSK",
      Description:
        "Leverage scalable, low-latency data streaming to enhance security and compliance using Amazon MSK for your financial institution. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-fraud-detection-for-financial-institutions-with-amazon-msk",
      Links:
        "https://mactores.com/blog/real-time-fraud-detection-for-financial-institutions-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Fraud_Detection.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Boost Healthcare Efficiency: Amazon MSK for Patient Flow & Resources",
      Description:
        "Discover how Amazon MSK enhances healthcare operations by optimizing patient flow and resource allocation, resulting in better patient care.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/boost-healthcare-efficiency-amazon-msk-for-patient-flow-resources",
      Links:
        "https://mactores.com/blog/boost-healthcare-efficiency-amazon-msk-for-patient-flow-resources",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Healthcare_Efficiency.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Cut Costs and Waste: Boost Manufacturing Efficiency with Amazon MSK",
      Description:
        "With Amazon MSK's real-time data analysis, reduce manufacturing costs and waste, boosting efficiency and sustainability through predictive maintenance.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/cut-costs-and-waste-boost-manufacturing-efficiency-with-amazon-msk",
      Links:
        "https://mactores.com/blog/cut-costs-and-waste-boost-manufacturing-efficiency-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Manufacturing_Cut_Costs.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Amazon MSK for Algorithmic Trading: Reliable High-Frequency Trading",
      Description:
        "Learn how to boost high-frequency trading with Amazon MSK for real-time, reliable data processing.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-msk-for-algorithmic-trading-reliable-high-frequency-trading",
      Links:
        "https://mactores.com/blog/amazon-msk-for-algorithmic-trading-reliable-high-frequency-trading",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Algorithmic_Trading_Fintech.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Amazon MSK to Enhance Viewing Experience with Real-Time Recommendation",
      Description:
        "Enhance user experience with real-time recommendations using Amazon MSK to deliver personalized content, analyze user behavior, and improve engagement.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-msk-to-enhance-viewing-experience-with-real-time-recommendation",
      Links:
        "https://mactores.com/blog/amazon-msk-to-enhance-viewing-experience-with-real-time-recommendation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Viewing_Experience.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Protect Your Content from Piracy: Comprehensive Guide Using Amazon MSK",
      Description:
        "Protect your content from piracy with Amazon MSK. It detects and prevents unauthorized distribution using real-time data analysis and security measures.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/protect-your-content-from-piracy-comprehensive-guide-using-amazon-msk",
      Links:
        "https://mactores.com/blog/protect-your-content-from-piracy-comprehensive-guide-using-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Content_Piracy.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Enhance Team Collaboration in Content Production with Amazon MSK ",
      Description:
        "Streamline content production with Amazon MSK, improve real-time collaboration, workflow automation, team communication for faster, more efficient results.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhance-team-collaboration-in-content-production-with-amazon-msk",
      Links:
        "https://mactores.com/blog/enhance-team-collaboration-in-content-production-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Team_Collaboration_Content.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title:
        "Optimize Smart Grids with Amazon MSK for Real-Time Energy Management",
      Description:
        "Optimize smart grids with Amazon MSK for real-time energy management, enabling efficient data processing, improved collaboration, and system reliability.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimize-smart-grids-with-amazon-msk-for-real-time-energy-management",
      Links:
        "https://mactores.com/blog/optimize-smart-grids-with-amazon-msk-for-real-time-energy-management",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Smart_Grid.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title:
        "Manage Fluctuations in Wind and Solar Power Generation With Amazon MSK",
      Description:
        "Manage wind and solar power generation fluctuations with Amazon MSK for real-time data processing, improving grid stability, forecasting, and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/manage-fluctuations-in-wind-and-solar-power-generation-with-amazon-msk",
      Links:
        "https://mactores.com/blog/manage-fluctuations-in-wind-and-solar-power-generation-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Manage_Fluctuations.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Harness Amazon MSK for Real-Time Offers and Recommendations in Finance",
      Description:
        "Manage wind and solar power generation fluctuations with Amazon MSK for real-time data processing, improving grid stability, forecasting, and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/harness-amazon-msk-for-real-time-offers-and-recommendations-in-finance",
      Links:
        "https://mactores.com/blog/harness-amazon-msk-for-real-time-offers-and-recommendations-in-finance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Real_Time_Finance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Optimizing Energy Costs: Real-Time Billing with Amazon MSK",
      Description:
        "Optimize energy costs with real-time billing through Amazon MSK. Discover scalable, automated pricing for enhanced efficiency and customer satisfaction.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-energy-costs-real-time-billing-with-amazon-msk",
      Links:
        "https://mactores.com/blog/optimizing-energy-costs-real-time-billing-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Energy_Billing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Monitoring and Analyzing Energy Usage With Amazon MSK",
      Description:
        "Monitor and analyze energy usage in real time with Amazon MSK. Enhance grid stability, efficiency, and sustainability using scalable, data-driven insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/monitoring-and-analyzing-energy-usage-with-amazon-msk",
      Links:
        "https://mactores.com/blog/monitoring-and-analyzing-energy-usage-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Monitoring_Energy_Usage.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Leveraging Amazon MSK for Real-Time Risk Analysis and Mitigation",
      Description:
        "Learn how MSK enables real-time risk analysis and mitigation with seamless data processing, instant threat detection, and automated responses.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/leveraging-amazon-msk-for-real-time-risk-analysis-and-mitigation",
      Links:
        "https://mactores.com/blog/leveraging-amazon-msk-for-real-time-risk-analysis-and-mitigation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Real_Time_Risk_Analysis.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How Amazon MSK Helps Meet Regulatory Data Retention Requirements",
      Description:
        "Learn how Amazon MSK helps businesses meet data retention and reporting requirements with scalable, secure, and compliant data management solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-amazon-msk-helps-meet-regulatory-data-retention-requirements",
      Links:
        "https://mactores.com/blog/how-amazon-msk-helps-meet-regulatory-data-retention-requirements",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Data_Retention.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon MSK: The Backbone of Microservices Communication",
      Description:
        "Boost microservices communication with Amazon MSK, enabling real-time data, scalability, and resilience.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-msk-the-backbone-of-microservices-communication",
      Links:
        "https://mactores.com/blog/amazon-msk-the-backbone-of-microservices-communication",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Microservices_Bone.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "How MSK Maximize Ad Revenue with Real-Time Analytics for Ad Targeting",
      Description:
        "Explore how Amazon MSK powers real-time analytics for ad targeting, boosting revenue with personalized, timely ads and optimized data-driven decisions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-msk-maximize-ad-revenue-with-real-time-analytics-for-ad-targeting",
      Links:
        "https://mactores.com/blog/how-msk-maximize-ad-revenue-with-real-time-analytics-for-ad-targeting",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Ad_Targeting_Revenue.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Real-Time Log Monitoring to Boost App Performance with Amazon MSK",
      Description:
        "Boost app performance with real-time log monitoring using Amazon MSK. Discover how to improve reliability, scalability, and security with seamless insights",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-log-monitoring-to-boost-app-performance-with-amazon-msk",
      Links:
        "https://mactores.com/blog/real-time-log-monitoring-to-boost-app-performance-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Log_Monitoring_Real_Time.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title: "Real-Time Content Recommendation & Delivery with Amazon MSK",
      Description:
        "Enhance user engagement and boost revenue with real-time content recommendations powered by Amazon MSK.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-content-recommendation-delivery-with-amazon-msk",
      Links:
        "https://mactores.com/blog/real-time-content-recommendation-delivery-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Content_Real_Time.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Leveraging Amazon MSK for Real-Time Viewer Behavior Analysis",
      Description:
        "Discover how Amazon MSK enables real-time viewer behavior analysis for media companies, enhancing content personalization and driving audience engagement.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/leveraging-amazon-msk-for-real-time-viewer-behavior-analysis",
      Links:
        "https://mactores.com/blog/leveraging-amazon-msk-for-real-time-viewer-behavior-analysis",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Real_Time_Behaviour.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "How Amazon MSK Powers Real-Time Interactions for Immersive Gaming",
      Description:
        "Learn how Amazon MSK enables real-time interactions and events to help developers create immersive gaming experiences with seamless, low-latency gameplay.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-amazon-msk-powers-real-time-interactions-for-immersive-gaming",
      Links:
        "https://mactores.com/blog/how-amazon-msk-powers-real-time-interactions-for-immersive-gaming",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Gaming_Real_Time_Immersive.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Protecting Your Game from Fraud: Secure Data with Amazon MSK",
      Description:
        "Cheating costs the gaming industry billions in revenue and reputation. Learn how to prevent fraud and protect your game's integrity with smart solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/protecting-your-game-from-fraud-secure-data-with-amazon-msk",
      Links:
        "https://mactores.com/blog/protecting-your-game-from-fraud-secure-data-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_MSK_Game_Fraud.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Strategic Analytics Consulting for FinTech",
      Description:
        "Strategic Analytics Consulting for FinTech by Mactores empowers FinTech companies to unlock the potential of data analytics and machine learning, driving innovation, scalability, and competitiveness.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Strategic%20Analytics%20Consulting%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Real-Time Streaming for Manufacturers",
      Description:
        "Transform your manufacturing operations with Mactores’ Data Platform Modernization Automation. This end-to-end solution migrates your systems from Telegraf to AWS IoT Core and InfluxDB to AWS TimeStream, leveraging Amazon Kinesis Flink and Managed Kafka for real-time streaming.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Real-Time%20Streaming%20for%20Manufacturers.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "AI-Driven Product Development For Internet Software",
      Description:
        "Accelerate product development with Mactores' AI-Driven Product Development for Internet Software. Harnessing Data Analytics, Generative AI, and Machine Learning, we empower software companies to innovate faster, optimize operations, and scale efficiently.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/AI-Driven%20Product%20Development%20For%20Internet%20Software%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Predictive Maintenance: Prevent Energy Downtime with Amazon MSK",
      Description:
        "Learn how predictive maintenance with Amazon MSK prevents energy downtime, reduces costs, and boosts reliability through real-time data insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/predictive-maintenance-prevent-energy-downtime-with-amazon-msk",
      Links:
        "https://mactores.com/blog/predictive-maintenance-prevent-energy-downtime-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_MSK_Predictive_Maintenance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Personalized Gaming: Real-Time Player Analytics with Amazon MSK",
      Description:
        "Learn how real-time player analytics and Amazon MSK help personalize gaming experiences, enhancing player engagement with tailored recommendations.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/personalized-gaming-real-time-player-analytics-with-amazon-msk",
      Links:
        "https://mactores.com/blog/personalized-gaming-real-time-player-analytics-with-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_MSK_Personalized_Gaming.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Optimizing Gameplay with Real-Time Data Analytics via Amazon MSK",
      Description:
        "Optimize gameplay, boost revenue with Amazon MSK, and learn how real-time game analytics can enhance player experience and drive monetization strategies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-gameplay-with-real-time-data-analytics-via-amazon-msk",
      Links:
        "https://mactores.com/blog/optimizing-gameplay-with-real-time-data-analytics-via-amazon-msk",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_MSK_Gaming_Real_Time_Analytics.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Platform Migration For Internet Software",
      Description:
        "Our Automated Data Platform Migration solution leverages AWS services like Amazon RDS, AWS Database Migration Service (DMS), Amazon Redshift, and AWS Glue to optimize migrations at scale.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Platform%20Migration%20For%20Internet%20Software.jpg",
    },
  ],

  redshiftList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Deep Dive: Enterprise Data Warehouse Migrations",
      Description:
        "Learn complex migration of EDW from one platform to another or on-premises to the cloud. Enables new features, improves performance, and reduces costs.",
      ButtonText: "View",
      URL: "Deep Dive: Enterprise Data Warehouse Migrations",
      Links:
        "https://mactores.com/blog/deep-dive-enterprise-data-warehouse-migrations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Deep%20Dive.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "3x Performance: Oracle to Amazon Redshift Migration with Zero Downtime",
      Description:
        "Discover how Mactores achieved tripled performance with zero downtime by migrating from Oracle to Amazon Redshift",
      ButtonText: "View",
      URL: "3x Performance: Oracle to Amazon Redshift Migration with Zero Downtime",
      Links:
        "https://mactores.com/blog/3x-performance-oracle-to-amazon-redshift-migration-with-zero-downtime",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/3x%20Performance.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Driven Everything Workshop and MVP",
      Description:
        'Our "Data Driven Everything Workshop" is designed to guide businesses through the necessary steps to make this shift, including defining customer personas and their problems, creating data-driven value stories, and visualizing future state experiences. The MVP will involve building data pipelines using relevant AWS Services like Amazon S3, Amazon EMR, AWS Glue, Amazon Redshift, and Amazon Sagemaker.',
      ButtonText: "View",
      URL: "Data Driven Everything Workshop and MVP",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-urzzrqrd7hzmo?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Driven%20Everything%20Workshop%20and%20MVP.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title:
        "Mactores Migration Suite for MS-SQL Data Warehouse to Amazon Redshift",
      Description:
        "The Mactores Migration Suite for Microsoft SQL Data Warehouse to Amazon Redshift is a comprehensive solution that simplifies the migration process",
      ButtonText: "View",
      URL: "Mactores Migration Suite for MS-SQL Data Warehouse to Amazon Redshift",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-w43rk47qdg2c4?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Migration%20Suite%20for%20MS-SQL%20Data%20Warehouse%20to%20Amazon%20Redshift.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Greenplum to Amazon Redshift Migration Assessment",
      Description:
        "Greenplum to Amazon Redshift Migration Assessment accelerates the discovery process with automated tools that collect information about your DW and connected applications",
      ButtonText: "View",
      URL: "Greenplum to Amazon Redshift Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tpxjp7fnahtaw?sr=0-4&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Greenplum%20to%20Amazon%20Redshift%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Video",
      Industry: "Internet Software",
      Title: "Ask Us Anything on Amazon Redshift: Trends, Solutions, and Myths",
      Description:
        "Let’s face it, as data professionals, we often wonder about the trends and innovations happening in the data warehousing community. Watch this video to get your questions addressed by data experts",
      ButtonText: "View",
      URL: "Ask Us Anything on Amazon Redshift: Trends, Solutions, and Myths - YouTube",
      Links: "https://www.youtube.com/watch?v=kudQMvAIamY",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Ask_Us_Redshift.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title:
        "Mactores Announces ChatGPT Integration for Its Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake",
      Description:
        "Mactores, a leading provider in data analytics, ML, and AI systems, announces the successful integration of ChatGPT into its Mactores Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake. This integration makes it easier for business users to interact with their data and derive usable insights.",
      ButtonText: "View",
      URL: "Mactores Announces ChatGPT Integration for Its Aedeon Data Lake on AWS, Amazon Redshift, and Snowflake",
      Links:
        "https://www.accesswire.com/745140/Mactores-Announces-ChatGPT-Integration-for-Its-Aedeon-Data-Lake-on-AWS-Amazon-Redshift-and-Snowflake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/ChatGPT_Integration.jpg",
    },
    {
      AssetType: "Article",
      Industry: "Internet Software",
      Title:
        "How Mactores Tripled Performance by Migrating from Oracle to Amazon Redshift with Zero Downtime",
      Description:
        "Discover how Mactores achieved tripled performance with zero downtime by migrating from Oracle to Amazon Redshift",
      ButtonText: "View",
      URL: "How Mactores Tripled Performance by Migrating from Oracle to Amazon Redshift with Zero Downtime",
      Links:
        "https://aws.amazon.com/blogs/apn/how-mactores-tripled-performance-by-migrating-from-oracle-to-amazon-redshift-with-zero-downtime/",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Mactores_Tripled_Performance.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of Amazon Redshift with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance data warehousing and analytics capabilities with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon Redshift",
      ButtonText: "View",
      URL: "Amazon Redshift Email",
      Links:
        "https://docs.google.com/document/d/1PuiuBU9dIdgdqXzg91qLhWMYTxDEG6krpKOISfkHPoo/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Redshift.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Financial Services",
      Title: "Cello Capital",
      Description:
        "Mortgage-backed Securities (MBS) Pricing and Analytics platform",
      ButtonText: "Download",
      URL: "Mortgage-backed Securities (MBS) Pricing and Analytics platform",
      Links:
        "https://mactores.com/mortgage-backed-securities-pricing-and-analytics-platform",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Cello_Capital_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Case studies",
      Industry: "Media Entertainment",
      Title: "Hansa Cequity",
      Description: "Multi-Channel Marketing Campaign Optimization at Scale",
      ButtonText: "Download",
      URL: "Multi-Channel Marketing with Contextual Intelligence",
      Links:
        "https://mactores.com/multi-channel-marketing-with-contextual-intelligence",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Case%20Study%20Images/Hansa_Cequity_Case_Study_Featured_Image.jpg",
    },
    {
      AssetType: "Solution Brief",
      Industry: "Internet Software",
      Title: "Revolutionize Data Insights with ChatGPT",
      Description:
        "Improve productivity by 5x using ChatGPT™ Integration with Mactores™ Aedeon® Data Lake on AWS, Amazon Redshift, and Snowflake",
      ButtonText: "Download",
      URL: "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      Links:
        "https://mactores.com/hubfs/Insights/Aedeon%20ChatGPT%20Solution%20Brief_v1.pdf",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/ChatGPT_Integration.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Automated DataOps: A Game-Changer for High-Volume Data Management",
      Description:
        "Unlock the potential of high-volume data with Automated DataOps. Learn how to scale, improve performance, and reduce errors for sustainable success.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-dataops-a-game-changer-for-high-volume-data-management",
      Links:
        "https://mactores.com/blog/automated-dataops-a-game-changer-for-high-volume-data-management",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Game_Changer_High_Volume_Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Success Stories: Businesses Migrating to Open-Source Databases",
      Description:
        "Discover how open-source databases help businesses thrive. Real success stories reveal innovation, scalability, and enhanced data management.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/success-stories-businesses-migrating-to-open-source-databases",
      Links:
        "https://mactores.com/blog/success-stories-businesses-migrating-to-open-source-databases",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Open_Source_Databases.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Compliance and Security Considerations for a Database Migration",
      Description:
        "Explore the importance of security and compliance in cloud migration. Learn how to protect sensitive data and ensure business continuity.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/compliance-and-security-considerations-for-a-database-migration",
      Links:
        "https://mactores.com/blog/compliance-and-security-considerations-for-a-database-migration",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Compliance_Security.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "AWS Enhancements for Amazon Redshift, Glue, Athena",
      Description:
        "Discover AWS advancements to explore faster insights, real-time integration, and scalable tools for smarter decisions and innovation.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/aws-enhancements-for-amazon-redshift-glue-athena",
      Links:
        "https://mactores.com/blog/aws-enhancements-for-amazon-redshift-glue-athena",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Athena_Enhancements.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Break Performance Barrier with Amazon Redshift Multi-Cluster",
      Description:
        "Break performance limits with Amazon Redshift Multi-Cluster. Scale seamlessly, optimize costs, and boost query speed. Discover why it’s a game-changer!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/break-performance-barrier-with-amazon-redshift-multi-cluster",
      Links:
        "https://mactores.com/blog/break-performance-barrier-with-amazon-redshift-multi-cluster",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Redshift_Multicluster_Performance_Barrier.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Transforming Data Integration with AWS Redshift and SaaS Applications",
      Description:
        "Transform data integration with AWS Redshift and SaaS apps. Streamline insights, boost security, and scale effortlessly.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/transforming-data-integration-with-aws-redshift-and-saas-applications",
      Links:
        "https://mactores.com/blog/transforming-data-integration-with-aws-redshift-and-saas-applications",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Integration_AWS_Redshift.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Custom AI Solution for FinTech",
      Description:
        "Mactores' Custom AI solutions for FinTech empower financial institutions to tackle challenges in fraud detection, compliance, and customer engagement through advanced machine learning, big data processing, and predictive analytics.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Custom%20AI%20Solution%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Strategic Analytics Consulting for FinTech",
      Description:
        "Strategic Analytics Consulting for FinTech by Mactores empowers FinTech companies to unlock the potential of data analytics and machine learning, driving innovation, scalability, and competitiveness.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Strategic%20Analytics%20Consulting%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Data Management and Governance For FinTech",
      Description:
        "Unlock your financial data’s potential with Mactores' Data Management & Governance services tailored for FinTech. Enhance decision-making, ensure compliance, and drive innovation with robust frameworks. ",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Management%20and%20Governance%20For%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Technology Integration and Optimization for FinTech",
      Description:
        "Mactores empowers FinTech firms to embrace digital transformation confidently by integrating AI, blockchain, and cloud technologies.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Technology%20Integration%20and%20Optimization%20for%20FinTech.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Zero-ETL Integrations in Amazon Redshift: re:Invent 2024 Update",
      Description:
        "re: Invent 2024 update: Read how Amazon Redshift's Zero-ETL integrations enable you to create faster and more effective data pipelines.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/zero-etl-integrations-in-amazon-redshift-re-invent-2024-update",
      Links:
        "https://mactores.com/blog/zero-etl-integrations-in-amazon-redshift-re-invent-2024-update",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Zero_ETL_Integration_Redshift.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Automated Database Migration (GenAI) For Internet Software",
      Description:
        "Mactores’ Automated Database Migration with Generative AI enables Internet and Software companies to seamlessly transition from legacy databases to cloud-native environments with minimal disruption.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Automated%20Database%20Migration%20(GenAI)%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Platform Migration For Internet Software",
      Description:
        "Our Automated Data Platform Migration solution leverages AWS services like Amazon RDS, AWS Database Migration Service (DMS), Amazon Redshift, and AWS Glue to optimize migrations at scale.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Platform%20Migration%20For%20Internet%20Software.jpg",
    },
  ],

  sagemakerList: [
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title:
        "Databricks to Amazon EMR and Amazon Sagemaker Migration Assessment",
      Description:
        "Are you looking to migrate your Databricks environment to Amazon EMR with Apache Hudi or Apache IceBerg and Amazon Sagemaker? Let Mactores help you with our comprehensive migration assessment service",
      ButtonText: "View",
      URL: "Databricks to Amazon EMR and Amazon Sagemaker Migration Assessment",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-jzf43qyfowi56?sr=0-4&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Databricks%20to%20Amazon%20EMR%20and%20Amazon%20Sagemaker%20Migration%20Assessment.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Driven Everything Workshop and MVP",
      Description:
        'Our "Data Driven Everything Workshop" is designed to guide businesses through the necessary steps to make this shift, including defining customer personas and their problems, creating data-driven value stories, and visualizing future state experiences. The MVP will involve building data pipelines using relevant AWS Services like Amazon S3, Amazon EMR, AWS Glue, Amazon Redshift, and Amazon Sagemaker.',
      ButtonText: "View",
      URL: "Data Driven Everything Workshop and MVP",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-urzzrqrd7hzmo?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Driven%20Everything%20Workshop%20and%20MVP.jpg",
    },
    {
      AssetType: "Video",
      Industry: "Internet Software",
      Title:
        "Forecasting sales for subscription companies using Machine Learning",
      Description:
        "Dan Marks and Nandan Umarji from Mactores along with Sanjay Doiphode, Senior Partner Solutions Architect at AWS are making an interesting connection between old business models versus new business models. Taking the discussion further they deep dive into describing how Machine Learning can help businesses forecast their sales precisely",
      ButtonText: "View",
      URL: "Forecasting sales for subscription companies using Machine Learning - YouTube",
      Links: "https://www.youtube.com/watch?v=Yszq3VgwRMc",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Flipbook,%20E-Book,%20Video%20Images/Forecasting_Sales_Subscription.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of Amazon SageMaker with our Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance machine learning and Gen AI initiatives with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, time savings, data security, and custom solutions in Amazon SageMaker",
      ButtonText: "View",
      URL: "Amazon Sagemaker Email",
      Links:
        "https://docs.google.com/document/d/1ve5dAaB1vfi5elHKz0gvtM2yimKik0PBV_Rss_z_NdA/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Sagemaker.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Impactful Cloud Computing Trends to Look For in 2023-2024",
      Description:
        "Discover 2023-2024 cloud trends with AWS: AI, Edge Computing, Blockchain. Unlock the power of transformative technology",
      ButtonText: "View",
      URL: "https://mactores.com/blog/impactful-cloud-computing-trends-to-look-for-in-2023-2024",
      Links:
        "https://mactores.com/blog/impactful-cloud-computing-trends-to-look-for-in-2023-2024",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Impactful_Cloud_Computing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Revolutionizing AI with Automated MLOps: A New Era of Machine Learning",
      Description:
        "Explore the transformative power of Automated MLOps in AI development. Learn key principles, real-world examples, and seamless integration strategies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/revolutionizing-ai-with-automated-mlops-a-new-era-of-machine-learning",
      Links:
        "https://mactores.com/blog/revolutionizing-ai-with-automated-mlops-a-new-era-of-machine-learning",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Revolutionizing_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "The Impact of Automated MLOps on Business Efficiency and Innovation",
      Description:
        "Unlock innovation and efficiency with automated MLOps. Streamline ML lifecycles, foster collaboration, and accelerate development for business success!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/the-impact-of-automated-mlops-on-business-efficiency-and-innovation",
      Links:
        "https://mactores.com/blog/the-impact-of-automated-mlops-on-business-efficiency-and-innovation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Impact_Of_Automated_MLOps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Simplified ML with Automated MLOps: Navigating Complexity",
      Description:
        "Discover how Automated MLOps simplifies the complexities of ML processes. Streamline data prep, training, deployment, and integration effortlessly!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/simplified-ml-with-automated-mlops-navigating-complexity",
      Links:
        "https://mactores.com/blog/simplified-ml-with-automated-mlops-navigating-complexity",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Simplified_ML.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Automated MLOps: Data Science to Operations Excellence",
      Description:
        "Unlock collaboration and efficiency with Automated MLOps. Learn how it streamlines data science workflows for operational excellence.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-mlops-data-science-to-operations-excellence",
      Links:
        "https://mactores.com/blog/automated-mlops-data-science-to-operations-excellence",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Science.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Automated MLOps: Scaling AI from Experimentation to Production",
      Description:
        "Unlock the full potential of AI with Automated MLOps, bridging the gap from experimentation to production for scalable business success",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-mlops-scaling-ai-from-experimentation-to-production",
      Links:
        "https://mactores.com/blog/automated-mlops-scaling-ai-from-experimentation-to-production",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Scaling_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Customer Churn Prediction Using Machine Learning",
      Description:
        "Discover the power of machine learning in predicting customer churn. Learn how to analyze data patterns and retain valuable customers effectively.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/customer-churn-prediction-using-machine-learning",
      Links:
        "https://mactores.com/blog/customer-churn-prediction-using-machine-learning",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Customer_Churn_Strategy.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Revolutionizing Data Management with Amazon SageMaker Lakehouse",
      Description:
        "Learn how Amazon SageMaker Lakehouse transforms data management with unified systems, real-time insights, and seamless machine learning integration.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/revolutionizing-data-management-with-amazon-sagemaker-lakehouse",
      Links:
        "https://mactores.com/blog/revolutionizing-data-management-with-amazon-sagemaker-lakehouse",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Lakehouse_Data_Management.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon SageMaker Studio: Centralized AI Development",
      Description:
        "Explore centralized AI development with Amazon SageMaker Unified Studio. Discover features, benefits, and how Mactores helps businesses lead with AI.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-sagemaker-studio-centralized-ai-development",
      Links:
        "https://mactores.com/blog/amazon-sagemaker-studio-centralized-ai-development",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Studio_AI_Development.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Achieving Data Governance with Amazon SageMaker Catalog",
      Description:
        "Discover how Amazon SageMaker Catalog simplifies data governance with centralized management and streamlined compliance for businesses.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/achieving-data-governance-with-amazon-sagemaker-catalog",
      Links:
        "https://mactores.com/blog/achieving-data-governance-with-amazon-sagemaker-catalog",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Catalog_Data_Governance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "SageMaker HyperPod: Scale AI Workloads with Task Governance",
      Description:
        "Amazon SageMaker HyperPod Explained. See what updates Amazon brings in re: Invent 2024 to make SageMaker more powerful and efficient for your AI workloads.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/sagemaker-hyperpod-scale-ai-workloads-with-task-governance",
      Links:
        "https://mactores.com/blog/sagemaker-hyperpod-scale-ai-workloads-with-task-governance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_HyperPod_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Unified Data and AI with Amazon SageMaker LakeHouse",
      Description:
        "Explore Amazon SageMaker LakeHouse's latest updates from re:Invent 2024 to optimize your AI solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/unified-data-and-ai-with-amazon-sagemaker-lakehouse",
      Links:
        "https://mactores.com/blog/unified-data-and-ai-with-amazon-sagemaker-lakehouse",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Lakehouse_Data_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Scale ML Workloads with SageMaker HyperPod Task Governance",
      Description:
        "Struggling to manage ML resources? SageMaker HyperPod's Task Governance offers centralized control, dynamic scheduling, and cost optimization. Learn more!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/scale-ml-workloads-with-sagemaker-hyperpod-task-governance",
      Links:
        "https://mactores.com/blog/scale-ml-workloads-with-sagemaker-hyperpod-task-governance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Hyperpod_ML_Workloads.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "re: Invent 2024 Update: AWS DataZone and SageMaker Catalog",
      Description:
        "AWS re:Invent 2024: DataZone and SageMaker get major updates for enhanced data governance, security, and compliance in the cloud.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/re-invent-2024-update-aws-datazone-and-sagemaker-catalog",
      Links:
        "https://mactores.com/blog/re-invent-2024-update-aws-datazone-and-sagemaker-catalog",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Data_Zone.png",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Aedeon Data Fabric Solution for Factory Operations",
      Description:
        "Unify, analyze, and optimize your factory operations with our AWS-based Aedeon Data Fabric solution. This solution delivers real-time insights, predictive analytics, and actionable intelligence by utilizing tools like Amazon QuickSight, Amazon Sagemaker, and Amazon Lambda to drive operational excellence.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Aedeon%20Data%20Fabric%20Solution%20for%20Factory%20Operations.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores GenAI Strategy Workshop ",
      Description:
        "Mactores offers a GenAI strategy workshop that develops a comprehensive AI strategy aligned with your business goals. We help you define your roadmap, resources, and success metrics to ensure a smooth and successful AI implementation.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-a24zwukgbrjcq?sr=0-9&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-a24zwukgbrjcq?sr=0-9&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20GenAI%20Strategy%20Workshop%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Custom AI Solution for FinTech",
      Description:
        "Mactores' Custom AI solutions for FinTech empower financial institutions to tackle challenges in fraud detection, compliance, and customer engagement through advanced machine learning, big data processing, and predictive analytics.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Custom%20AI%20Solution%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Strategic Analytics Consulting for FinTech",
      Description:
        "Strategic Analytics Consulting for FinTech by Mactores empowers FinTech companies to unlock the potential of data analytics and machine learning, driving innovation, scalability, and competitiveness.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Strategic%20Analytics%20Consulting%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Data Management and Governance For FinTech",
      Description:
        "Unlock your financial data’s potential with Mactores' Data Management & Governance services tailored for FinTech. Enhance decision-making, ensure compliance, and drive innovation with robust frameworks. ",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Management%20and%20Governance%20For%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Technology Integration and Optimization for FinTech",
      Description:
        "Mactores empowers FinTech firms to embrace digital transformation confidently by integrating AI, blockchain, and cloud technologies.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Technology%20Integration%20and%20Optimization%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Real-Time Streaming for Manufacturers",
      Description:
        "Transform your manufacturing operations with Mactores’ Data Platform Modernization Automation. This end-to-end solution migrates your systems from Telegraf to AWS IoT Core and InfluxDB to AWS TimeStream, leveraging Amazon Kinesis Flink and Managed Kafka for real-time streaming.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Real-Time%20Streaming%20for%20Manufacturers.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Data Fabric For Factory Operations In Manufacturing",
      Description:
        "Optimize your manufacturing operations on AWS with Mactores' Data Fabric. Integrate IoT data from your factory floor with AWS IoT services, leverage Amazon SageMaker for machine learning, and gain real-time insights with Amazon QuickSight to enhance efficiency, reduce downtime, and improve sustainability.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Data%20Fabric%20For%20Factory%20Operations%20In%20Manufacturing.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "AI-Driven Product Development For Internet Software",
      Description:
        "Accelerate product development with Mactores' AI-Driven Product Development for Internet Software. Harnessing Data Analytics, Generative AI, and Machine Learning, we empower software companies to innovate faster, optimize operations, and scale efficiently.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/AI-Driven%20Product%20Development%20For%20Internet%20Software%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Amazon SageMaker Lakehouse: Unified Data Access for ML and Analytics",
      Description:
        "Unify your data and AI & analytics with Amazon SageMaker Lakehouse. Break data silos, speed up ML workflows, and cut costs—no data migration needed!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-sagemaker-lakehouse-unified-data-access-for-ml-and-analytics",
      Links:
        "https://mactores.com/blog/amazon-sagemaker-lakehouse-unified-data-access-for-ml-and-analytics",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Unified_Data_Access_Sagemaker_Lakehouse.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "SageMaker Lakehouse Updates for Manufacturing Data Governance",
      Description:
        "Data governance has never been this efficient for manufacturers, thanks to the latest updates in Sagemaker Lakehouse. A blog you don’t want to miss!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/sagemaker-lakehouse-updates-for-manufacturing-data-governance",
      Links:
        "https://mactores.com/blog/sagemaker-lakehouse-updates-for-manufacturing-data-governance",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Sagemaker_Lakehouse_Manufacturing.jpg",
    },
  ],

  timestreamList: [
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Database Modernization and Cost Saving Workshop",
      Description:
        "Database modernization is a crucial process for organizations looking to improve their data management practices. Assessment Workshop is a comprehensive and value-driven solution for modernizing and optimizing your databases. We'll align your use cases to the right databases such as, but not limited to; Amazon Aurora, Amazon DynamoDB, Amazon ElastiCache, Amazon Neptune, Amazon Timestream, and Amazon Relational Database Service",
      ButtonText: "View",
      URL: "Database Modernization and Cost Saving Workshop",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-bfeagrzaqnwmu?sr=0-6&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Database%20Modernization%20and%20Cost%20Saving%20Workshop.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of AWS Glue with a Valued AWS Partner",
      Description:
        "The email introduces an opportunity to enhance time-series data management and analytics capabilities with Mactores Cognition, an AWS Advanced Consulting Partner. It highlights Mactores' success, cost optimization, automation, scalability, performance, data security, and custom solutions in Amazon Timestream",
      ButtonText: "View",
      URL: "Amazon Timestream",
      Links:
        "https://docs.google.com/document/d/1MaBJIWuxV-QZYdGnbuuF6NvlDhJy29k2C6nCQHlVb9U/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Streamlining Manufacturing Data Ingestion with Amazon Timestream",
      Description:
        "Manufacturing with Amazon Timestream: Efficiently ingest, store, and analyze sensor data in real-time to enhance productivity, maintenance, and quality.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-manufacturing-data-ingestion-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/streamlining-manufacturing-data-ingestion-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Integrating IoT in Manufacturing with Amazon Timestream & AWS IoT Core",
      Description:
        "Boost manufacturing efficiency with AWS IoT Core and Timestream: real-time data insights, reduced downtime, and improved product quality. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/integrating-iot-in-manufacturing-with-amazon-timestream-aws-iot-core",
      Links:
        "https://mactores.com/blog/integrating-iot-in-manufacturing-with-amazon-timestream-aws-iot-core",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_IoT_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Optimizing Media Data Analysis with Efficient Queries in Amazon Timestream",
      Description:
        "Media data analysis with Amazon Timestream gains real-time insights, improves engagement, and streamlines content delivery with time-series queries.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-media-data-analysis-efficient-queries-in-amazon-timestream",
      Links:
        "https://mactores.com/blog/optimizing-media-data-analysis-efficient-queries-in-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Media_Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Data Management Strategies for Health Care Using Amazon Timestream",
      Description:
        "Enhance patient care, reduce costs, and streamline operations with efficient, real-time time-series data analysis with Amazon Timestream.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-management-strategies-for-health-care-using-amazon-timestream",
      Links:
        "https://mactores.com/blog/data-management-strategies-for-health-care-using-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Healthcare.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "Ensuring HA and DR for Financial Data in Amazon Timestream",
      Description:
        "Ensure HA and DR for financial data in Amazon Timestream. Learn best practices to maintain data integrity, regulatory compliance, and business continuity.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/ensuring-ha-and-dr-for-financial-data-in-amazon-timestream",
      Links:
        "https://mactores.com/blog/ensuring-ha-and-dr-for-financial-data-in-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Financial_Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Securing Your Software Pipeline: HA and DR in Amazon Timestream",
      Description:
        "Ensure HA and DR for software data in Amazon Timestream. Learn best practices to keep development uninterrupted and maintain data integrity.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/securing-your-software-pipeline-ha-and-dr-in-amazon-timestream",
      Links:
        "https://mactores.com/blog/securing-your-software-pipeline-ha-and-dr-in-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Software_Pipeline.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title: "Media Data Security with Amazon Timestream",
      Description:
        "Secure your media data with Amazon Timestream. Learn how this scalable time series database service enhances data security for media companies.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/media-data-security-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/media-data-security-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Media_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Effective Time Series Data Models In Healthcare with Amazon Timestream",
      Description:
        "Discover how Amazon Timestream enhances healthcare with efficient time series data models. Improve patient care, predict trends, and streamline operations.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/effective-time-series-data-models-in-healthcare-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/effective-time-series-data-models-in-healthcare-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Health_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "Advanced Query Techniques in Amazon Timestream for Gaming Insights",
      Description:
        "Unlock gaming insights with Amazon Timestream's advanced queries. Boost player engagement, analyze real-time behavior, stay ahead in the gaming industry.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/advanced-query-techniques-in-amazon-timestream-for-gaming-insights",
      Links:
        "https://mactores.com/blog/advanced-query-techniques-in-amazon-timestream-for-gaming-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Game_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Automating Data Management - Financial Services with Amazon Timestream",
      Description:
        "Streamline data management in financial services with Amazon Timestream. Gain real-time insights, ensure compliance, and reduce costs with data workflows.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-data-management-financial-services-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/automating-data-management-financial-services-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Finance_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "Optimizing Game Performance Efficient Queries in Amazon Timestream",
      Description:
        "Leverage fast, scalable queries to enhance player experience and analyze frame rates, latency, and resource consumption with Amazon Timestream.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-game-performance-efficient-queries-in-amazon-timestream",
      Links:
        "https://mactores.com/blog/optimizing-game-performance-efficient-queries-in-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Optimize_Game_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Advanced Query Techniques in Amazon Timestream for Financial Insights",
      Description:
        "Learn advanced query techniques for trend analysis, anomaly detection, and real-time financial data analytics with Amazon Timestream.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/advanced-query-techniques-in-amazon-timestream-for-financial-insights",
      Links:
        "https://mactores.com/blog/advanced-query-techniques-in-amazon-timestream-for-financial-insights",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Advanced_Query_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Visualizing Software Performance with Amazon Timestream and QuickSight",
      Description:
        "Visualize real-time data, identify bottlenecks, and optimize efficiency effortlessly with Amazon Timestream and QuickSight.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/visualizing-software-performance-with-amazon-timestream-and-quicksight",
      Links:
        "https://mactores.com/blog/visualizing-software-performance-with-amazon-timestream-and-quicksight",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Visualizing_Software_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Automating Data Management in Energy with Amazon Timestream",
      Description:
        "Enhance operations, compliance, and decision-making using scalable, real-time insights tailored for the energy sector with Amazon Timestream.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-data-management-in-energy-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/automating-data-management-in-energy-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Management_Energy_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "Securing Financial Data with Amazon Timestream",
      Description:
        "Secure financial data with Amazon Timestream. Discover encryption, compliance, and real-time insights to safeguard your critical financial information.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/securing-financial-data-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/securing-financial-data-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Securing_Financial_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Detect Anomalies in Energy Usage with Amazon Timestream and ML",
      Description:
        "Enhance efficiency, reduce costs, and promote sustainability with real-time insights with Amazon Timestream and Machine Learning in Energy. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/detect-anomalies-in-energy-usage-with-amazon-timestream-and-ml",
      Links:
        "https://mactores.com/blog/detect-anomalies-in-energy-usage-with-amazon-timestream-and-ml",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Detecting_Anomalies_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Design Time Series Data Models for Internet Software with Timestream",
      Description:
        "Using Amazon Timestream, design efficient time series data models for internet software. Enhance scalability, performance, and cost-effectiveness.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/design-time-series-data-models-for-internet-software-with-timestream",
      Links:
        "https://mactores.com/blog/design-time-series-data-models-for-internet-software-with-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Time_Series_Data_Models_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Optimizing Health Care Analytics with Amazon Timestream",
      Description:
        "Optimize healthcare analytics with Amazon Timestream queries for faster, cost-effective insights from patient data, enhancing care and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-health-care-analytics-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/optimizing-health-care-analytics-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Healthcare_Analytics_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Securing Software Performance Data with Amazon Timestream",
      Description:
        "Software performance data with Amazon Timestream for scalable, cost-efficient management and robust analytics, ensuring optimal application performance.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/securing-software-performance-data-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/securing-software-performance-data-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Software_Performance_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title:
        "Real-Time Health Care Analytics with Amazon Timestream and AWS Lambda",
      Description:
        "Real-time healthcare analytics with Amazon Timestream and AWS Lambda for early disease detection, improved patient care, and optimized resource allocation.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-health-care-analytics-with-amazon-timestream-and-aws-lambda",
      Links:
        "https://mactores.com/blog/real-time-health-care-analytics-with-amazon-timestream-and-aws-lambda",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Healthcare_Lambda_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "Cost-Effective Data Management for Gaming Using Amazon Timestream",
      Description:
        "Optimize gaming data with Amazon Timestream's cost-effective, scalable, real-time processing and analysis solutions and enhance performance. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/cost-effective-data-management-for-gaming-using-amazon-timestream",
      Links:
        "https://mactores.com/blog/cost-effective-data-management-for-gaming-using-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Cost_Effective_Gaming_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Data Management Strategies for Internet Software with Amazon Timestream",
      Description:
        "Learn how AWS Timestream optimizes cost-effective data management for internet software, with high volumes of time-series data with speed and efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/data-management-strategies-for-internet-software-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/data-management-strategies-for-internet-software-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Management_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Streamlining Gaming Data Ingestion with Amazon Timestream",
      Description:
        "Streamline gaming data ingestion with Amazon Timestream for scalable, cost-efficient, and real-time analytics.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-gaming-data-ingestion-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/streamlining-gaming-data-ingestion-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Gaming_Data_Ingestion_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Amazon Timestream vs Other Time Series DBs for Media and Entertainment",
      Description:
        "Learn Amazon Timestream outperforms other time series databases in media and entertainment, enhancing real-time insights, scalability, and cost efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-timestream-vs-other-time-series-dbs-for-media-and-entertainment",
      Links:
        "https://mactores.com/blog/amazon-timestream-vs-other-time-series-dbs-for-media-and-entertainment",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Media_Entertainment.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Amazon Timestream vs. Other Time Series Databases for Energy",
      Description:
        "Compare Amazon Timestream to other time-series databases for the energy sector. Discover the best fit for efficient data storage, processing, and analysis.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-timestream-vs-other-time-series-databases-for-energy",
      Links:
        "https://mactores.com/blog/amazon-timestream-vs-other-time-series-databases-for-energy",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Energy_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title: "Revolutionize Media & Entertainment with Amazon Timestream",
      Description:
        "Learn how Amazon Timestream revolutionizes media with real-time data analytics, enhancing decision-making, audience engagement, and operational efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/revolutionize-media-entertainment-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/revolutionize-media-entertainment-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Revolutionize_Media_Entertainment_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Streamlining Energy Data Ingestion with Amazon Timestream",
      Description:
        "Streamline energy data ingestion with Amazon Timestream—scalable, cost-effective, and real-time insights for optimizing and enhancing decision-making.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-energy-data-ingestion-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/streamlining-energy-data-ingestion-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Streamline_Energy_Amazon_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Automating Data Management in Manufacturing with Amazon Timestream",
      Description:
        "Automate manufacturing data management with Amazon Timestream for real-time insights, faster queries, and improved efficiency in handling time-series data.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-data-management-in-manufacturing-with-amazon-timestream",
      Links:
        "https://mactores.com/blog/automating-data-management-in-manufacturing-with-amazon-timestream",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Data_Management_Amazon_Timestream-1.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "Visualizing Financial Data with Amazon Timestream and QuickSight",
      Description:
        "Visualize financial data with Amazon Timestream and QuickSight for real-time insights, powerful analytics, and actionable decisions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/visualizing-financial-data-with-amazon-timestream-and-quicksight",
      Links:
        "https://mactores.com/blog/visualizing-financial-data-with-amazon-timestream-and-quicksight",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Fintech_Timestream_QuickSight.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title:
        "Enhance Media Production with IoT via Amazon Timestream & AWS IoT Core",
      Description:
        "Discover how integrating IoT in media production with Amazon Timestream and AWS IoT Core enhances real-time data analysis, automation, workflow efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhance-media-production-with-iot-via-amazon-timestream-aws-iot-core",
      Links:
        "https://mactores.com/blog/enhance-media-production-with-iot-via-amazon-timestream-aws-iot-core",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Timestream_IoT_Media_Production.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "High Availability & Disaster Recovery with Amazon Timestream in Gaming",
      Description:
        "Discover how Amazon Timestream ensures high availability and disaster recovery for gaming with real-time data management and auto-scaling.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/high-availability-disaster-recovery-with-amazon-timestream-in-gaming",
      Links:
        "https://mactores.com/blog/high-availability-disaster-recovery-with-amazon-timestream-in-gaming",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_HA_Gaming.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Amazon Timestream vs. Other Time Series Databases for Manufacturing",
      Description:
        "Compare Amazon Timestream with top time series databases for manufacturing. Learn how the right choice can optimize your operations and boost efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-timestream-vs-other-time-series-databases-for-manufacturing",
      Links:
        "https://mactores.com/blog/amazon-timestream-vs-other-time-series-databases-for-manufacturing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_vs_Other_Timestream.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Real-Time Energy Analytics with Amazon Timestream and AWS Lambda",
      Description:
        "Discover how Amazon Timestream and AWS Lambda enable real-time energy analytics to optimize energy consumption and reduce costs with scalable solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-energy-analytics-with-amazon-timestream-and-aws-lambda",
      Links:
        "https://mactores.com/blog/real-time-energy-analytics-with-amazon-timestream-and-aws-lambda",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Timestream_Energy_Analytics.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Real-Time Streaming for Manufacturers",
      Description:
        "Transform your manufacturing operations with Mactores’ Data Platform Modernization Automation. This end-to-end solution migrates your systems from Telegraf to AWS IoT Core and InfluxDB to AWS TimeStream, leveraging Amazon Kinesis Flink and Managed Kafka for real-time streaming.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Real-Time%20Streaming%20for%20Manufacturers.jpg",
    },
  ],

  glueList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How to Launch a Modern Data Platform in 90 Days",
      Description:
        "Build a modern data platform by integrating scalable data lakes, data warehouses, data stores, analytics, processing services, and security measures.",
      ButtonText: "View",
      URL: "How to Launch a Modern Data Platform in 90 Days",
      Links:
        "https://mactores.com/blog/how-to-launch-a-modern-data-platform-in-90-days",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Modern%20Data%20Platform.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How to modernize your data platform faster with cloud-based ETL",
      Description:
        "The Mactores ETL Migration Accelerator uses automation for faster assessment, migration, and modernization of your data platform",
      ButtonText: "View",
      URL: "How to modernize your data platform faster with cloud-based ETL",
      Links:
        "https://mactores.com/blog/how-to-modernize-your-data-platform-faster-with-cloud-based-etl",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Cloud%20Based%20ETL.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Driven Everything Workshop and MVP",
      Description:
        'Our "Data Driven Everything Workshop" is designed to guide businesses through the necessary steps to make this shift, including defining customer personas and their problems, creating data-driven value stories, and visualizing future state experiences. The MVP will involve building data pipelines using relevant AWS Services like Amazon S3, Amazon EMR, AWS Glue, Amazon Redshift, and Amazon Sagemaker.',
      ButtonText: "View",
      URL: "Data Driven Everything Workshop and MVP",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-urzzrqrd7hzmo?sr=0-5&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Driven%20Everything%20Workshop%20and%20MVP.jpg",
    },
    {
      AssetType: "Email",
      Industry: "Internet Software",
      Title: "Unlock the Power of AWS Glue with a Valued AWS Partner",
      Description:
        "The email introduces the opportunity to enhance your data integration and ETL processes by partnering with AWS experts, Mactores Cognition, Inc. We offer proven success, cost optimization, scalability, automation, time/cost savings, data security, and custom solutions with AWS Glue",
      ButtonText: "View",
      URL: "AWS Glue Email",
      Links:
        "https://docs.google.com/document/d/1oNulkLwKuEyH6Lls07PuyvGMiS4DE1vpsNOWAjxKmkY/edit?usp=drive_link",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Email%20Images/Amazon%20Glue.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Revolutionizing Data Management: The Rise of Fully Automated DataOps",
      Description:
        "Explore the transformative power of automated DataOps with AWS, revolutionizing data management for efficiency and innovation in the era of big data.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/revolutionizing-data-management-the-rise-of-fully-automated-dataops",
      Links:
        "https://mactores.com/blog/revolutionizing-data-management-the-rise-of-fully-automated-dataops",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Revolutionizing_Data_Management.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Automated DataOps: Enhancing Business Agility in the Data Tsunami",
      Description:
        "Unleash business agility with Automated DataOps on AWS. Navigate the data tsunami seamlessly. Learn key principles and integration strategies",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-dataops-enhancing-business-agility-in-the-data-tsunami",
      Links:
        "https://mactores.com/blog/automated-dataops-enhancing-business-agility-in-the-data-tsunami",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Automated_DataOps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "The Future of Data Quality and Security in Automated DataOps",
      Description:
        "Discover how Automated DataOps & AWS revolutionize data management and security for your organization's future success!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/the-future-of-data-quality-and-security-in-automated-dataops",
      Links:
        "https://mactores.com/blog/the-future-of-data-quality-and-security-in-automated-dataops",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Future_Data_Quality.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Maximizing ROI in Data Analytics: The Role of Fully Automated DataOps",
      Description:
        "Unlock substantial ROI in data analytics with Automated DataOps, powered by AWS Cloud tools. Streamline processes, ensure quality, and drive efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/maximizing-roi-in-data-analytics-the-role-of-fully-automated-dataops",
      Links:
        "https://mactores.com/blog/maximizing-roi-in-data-analytics-the-role-of-fully-automated-dataops",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Maximizing_ROI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Automated DataOps: A Game-Changer for High-Volume Data Management",
      Description:
        "Unlock the potential of high-volume data with Automated DataOps. Learn how to scale, improve performance, and reduce errors for sustainable success.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-dataops-a-game-changer-for-high-volume-data-management",
      Links:
        "https://mactores.com/blog/automated-dataops-a-game-changer-for-high-volume-data-management",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Game_Changer_High_Volume_Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "ETL Testing: A Step-by-Step Guide for Ensuring Data Integrity",
      Description:
        "Ensure data integrity with our step-by-step ETL testing guide. Learn to identify and rectify errors in the ETL pipeline for reliable insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/etl-testing-a-step-by-step-guide-for-ensuring-data-integrity",
      Links:
        "https://mactores.com/blog/etl-testing-a-step-by-step-guide-for-ensuring-data-integrity",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/ETL_Testing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "What is Zero ETL? Is it Better Than the Traditional ETL Method?",
      Description:
        "Know the benefits of Zero ETL vs traditional ETL. Learn which data integration method suits your business needs for faster insights and reduced complexity.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/what-is-zero-etl-is-it-better-than-the-traditional-etl-method",
      Links:
        "https://mactores.com/blog/what-is-zero-etl-is-it-better-than-the-traditional-etl-method",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Zero_ETL_vs_ETL.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Zero ETL: Benefits and Limitations",
      Description:
        "Zero ETL: A streamlined data integration approach with minimal transformations, it's benefits, limitations, and how it compares to traditional ETL methods.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/zero-etl-benefits-and-limitations",
      Links: "https://mactores.com/blog/zero-etl-benefits-and-limitations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Zero_ETL_Benefits.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Enhancing Software Performance Monitoring with Amazon Glue",
      Description:
        "Enhance software performance monitoring with Amazon Glue by automating ETL, integrating data, and optimizing real-time insights for better efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhancing-software-performance-monitoring-with-amazon-glue",
      Links:
        "https://mactores.com/blog/enhancing-software-performance-monitoring-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Software_Performance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Automating Data Workflows for Product Analytics with Amazon Glue",
      Description:
        "Automate your data workflows for product analytics with Amazon Glue to boost efficiency, scalability, and real-time insights for smarter business decisions",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-data-workflows-for-product-analytics-with-amazon-glue",
      Links:
        "https://mactores.com/blog/automating-data-workflows-for-product-analytics-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Data_Workflows.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Simplifying SaaS Data Integration with Amazon Glue",
      Description:
        "Simplify SaaS data integration with Amazon Glue's automated ETL, enabling scalable and cost-effective data pipelines for faster insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/simplifying-saas-data-integration-with-amazon-glue",
      Links:
        "https://mactores.com/blog/simplifying-saas-data-integration-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Saas_Data_Integration.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Enhancing Energy Consumption Analytics with Amazon Glue",
      Description:
        "Enhance energy consumption analytics with Amazon Glue's automated ETL, real-time data processing, and seamless integration for smarter energy decisions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhancing-energy-consumption-analytics-with-amazon-glue",
      Links:
        "https://mactores.com/blog/enhancing-energy-consumption-analytics-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Energy_Consumption.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Predicting Equipment Failures in Energy with Amazon Glue",
      Description:
        "Predict equipment failures in the energy sector with Amazon Glue's data integration and predictive analytics to improve efficiency and reduce downtime.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/predicting-equipment-failures-in-energy-with-amazon-glue",
      Links:
        "https://mactores.com/blog/predicting-equipment-failures-in-energy-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Energy_Equipment_Failures.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Build Real-Time Data Pipelines for Cloud Applications with Amazon Glue",
      Description:
        "Discover how to build real-time data pipelines for cloud applications with Amazon Glue, covering key steps and industry insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/build-real-time-data-pipelines-for-cloud-applications-with-amazon-glue",
      Links:
        "https://mactores.com/blog/build-real-time-data-pipelines-for-cloud-applications-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Cloud_Applications.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Optimizing Energy Trading with Amazon Glue Data Pipelines",
      Description:
        "Optimize energy trading with Amazon Glue data pipelines for real-time insights, seamless data integration, and cost efficiency.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-energy-trading-with-amazon-glue-data-pipelines",
      Links:
        "https://mactores.com/blog/optimizing-energy-trading-with-amazon-glue-data-pipelines",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Energy_Trading.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Optimizing User Experience with Data-Driven Insights Using Amazon Glue",
      Description:
        "Discover how Amazon Glue's data-driven insights enhance user experience through ETL, real-time data processing, and automation for better business outcomes",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-user-experience-with-data-driven-insights-using-amazon-glue",
      Links:
        "https://mactores.com/blog/optimizing-user-experience-with-data-driven-insights-using-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Data_Driven_Insights.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Real-Time In-Game Data Processing Using Amazon Glue",
      Description:
        "Real-time in-game data with Amazon Glue for seamless player engagement, low-latency gameplay, and personalized experiences in modern gaming environments.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/real-time-in-game-data-processing-using-amazon-glue",
      Links:
        "https://mactores.com/blog/real-time-in-game-data-processing-using-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Real_Time_Game_Data.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Enhancing Game Analytics with Amazon Glue’s ETL Capabilities",
      Description:
        "Boost game insights with Amazon Glue's ETL: enhance engagement, optimize retention, and increase revenue.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhancing-game-analytics-with-amazon-glues-etl-capabilities",
      Links:
        "https://mactores.com/blog/enhancing-game-analytics-with-amazon-glues-etl-capabilities",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Game_Analytics.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Automating Player Data Integration in Gaming with Amazon Glue",
      Description:
        "Automate player data integration with Amazon Glue to enhance gameplay, personalize experiences, and drive growth in the gaming industry.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-player-data-integration-in-gaming-with-amazon-glue",
      Links:
        "https://mactores.com/blog/automating-player-data-integration-in-gaming-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Player_Data_Integration.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title: "Streamlining Renewable Energy Forecasting with Amazon Glue",
      Description:
        "Discover how renewable companies can optimize energy forecasting to improve data accuracy, streamline workflows, and boost grid reliability.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-renewable-energy-forecasting-with-amazon-glue",
      Links:
        "https://mactores.com/blog/streamlining-renewable-energy-forecasting-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Energy_Forecasting.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Building Real-Time Financial Reporting Pipelines with Amazon Glue",
      Description:
        "Streamline ETL pipelines for real-time financial reporting, and enhance speed, accuracy, and compliance for smarter business decisions with Amazon Glue.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/building-real-time-financial-reporting-pipelines-with-amazon-glue",
      Links:
        "https://mactores.com/blog/building-real-time-financial-reporting-pipelines-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Financial_Reporting_Pipelines.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Enhancing Customer 360° Views in Financial Services with Amazon Glue",
      Description:
        "Gain a complete customer view of financial services with Amazon Glue. Integrate data, drive real-time insights, and enhance personalization efficiently.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhancing-customer-360-views-in-financial-services-with-amazon-glue",
      Links:
        "https://mactores.com/blog/enhancing-customer-360-views-in-financial-services-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Customer360_Finance.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title:
        "Streamlining Credit Risk Analysis with Amazon Glue and Data Lakes",
      Description:
        "Learn how Data Lakes and Amazon Glue enhance data integration, improve risk assessments, ensure compliance, and drive smarter financial decisions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-credit-risk-analysis-with-amazon-glue-and-data-lakes",
      Links:
        "https://mactores.com/blog/streamlining-credit-risk-analysis-with-amazon-glue-and-data-lakes",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Credit_Risk_Analysis.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Energy",
      Title:
        "Improving Energy Data Integration for Smart Grids with Amazon Glue",
      Description:
        "Learn how AWS Glue simplifies energy data integration for smart grids, enabling real-time processing, better forecasting, and efficient energy management. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/improving-energy-data-integration-for-smart-grids-with-amazon-glue",
      Links:
        "https://mactores.com/blog/improving-energy-data-integration-for-smart-grids-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Smart_Grid_Energy.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title: "Amazon Glue for Supply Chain Data Analytics in Manufacturing",
      Description:
        "Learn Amazon Glues benefits, use cases, and re: Invent 2024 updates to optimize efficiency and boost the profitability of supply chains in manufacturing.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-glue-for-supply-chain-data-analytics-in-manufacturing",
      Links:
        "https://mactores.com/blog/amazon-glue-for-supply-chain-data-analytics-in-manufacturing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Supply_Chain_Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title:
        "Building Scalable Data Pipelines for Multiplayer Games with Amazon Glue",
      Description:
        "Learn how to build scalable data pipelines for multiplayer games with Amazon Glue. Optimize gameplay, reduce latency, and handle massive data efficiently.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/build-scalable-data-pipelines-for-multiplayer-games-with-amazon-glue",
      Links:
        "https://mactores.com/blog/build-scalable-data-pipelines-for-multiplayer-games-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Scalable_Data_Pipelines_Gaming.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Financial Services",
      Title: "Improving Fraud Detection Pipelines with Amazon Glue",
      Description:
        "Discover how Amazon Glue enhances fraud detection pipelines in financial institutions with secure, scalable, and real-time data integration.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/improving-fraud-detection-pipelines-with-amazon-glue",
      Links:
        "https://mactores.com/blog/improving-fraud-detection-pipelines-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Fraud_Detection_Pipelines.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Creating Real-Time Audience Insights with Amazon Glue",
      Description:
        "Learn how Amazon Glue helps businesses create real-time audience insights, improve personalization, and boost operational efficiency with ease.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/creating-real-time-audience-insights-with-amazon-glue",
      Links:
        "http://mactores.com/blog/creating-real-time-audience-insights-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Real_Time_Audience_Insights.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Optimizing Content Delivery with Amazon Glue and Data Lakes",
      Description:
        "Learn how automation and personalization can transform your content strategies. Know the benefits of implementing Glue and data lakes in your tech stack.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/optimizing-content-delivery-with-amazon-glue-and-data-lakes",
      Links:
        "https://mactores.com/blog/optimizing-content-delivery-with-amazon-glue-and-data-lakes",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Glue_Optimize_Content_Delivery_Glue.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Aedeon Data Fabric Solution for Factory Operations",
      Description:
        "Unify, analyze, and optimize your factory operations with our AWS-based Aedeon Data Fabric solution. This solution delivers real-time insights, predictive analytics, and actionable intelligence by utilizing tools like Amazon QuickSight, Amazon Sagemaker, and Amazon Lambda to drive operational excellence.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Aedeon%20Data%20Fabric%20Solution%20for%20Factory%20Operations.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Custom AI Solution for FinTech",
      Description:
        "Mactores' Custom AI solutions for FinTech empower financial institutions to tackle challenges in fraud detection, compliance, and customer engagement through advanced machine learning, big data processing, and predictive analytics.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Custom%20AI%20Solution%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Strategic Analytics Consulting for FinTech",
      Description:
        "Strategic Analytics Consulting for FinTech by Mactores empowers FinTech companies to unlock the potential of data analytics and machine learning, driving innovation, scalability, and competitiveness.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-djhvnbynmewdu?sr=0-8&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Strategic%20Analytics%20Consulting%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Data Management and Governance For FinTech",
      Description:
        "Unlock your financial data’s potential with Mactores' Data Management & Governance services tailored for FinTech. Enhance decision-making, ensure compliance, and drive innovation with robust frameworks. ",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Management%20and%20Governance%20For%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Real-Time Streaming for Manufacturers",
      Description:
        "Transform your manufacturing operations with Mactores’ Data Platform Modernization Automation. This end-to-end solution migrates your systems from Telegraf to AWS IoT Core and InfluxDB to AWS TimeStream, leveraging Amazon Kinesis Flink and Managed Kafka for real-time streaming.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-nmmfer2ahjhgq?sr=0-3&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Real-Time%20Streaming%20for%20Manufacturers.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Data Fabric For Factory Operations In Manufacturing",
      Description:
        "Optimize your manufacturing operations on AWS with Mactores' Data Fabric. Integrate IoT data from your factory floor with AWS IoT services, leverage Amazon SageMaker for machine learning, and gain real-time insights with Amazon QuickSight to enhance efficiency, reduce downtime, and improve sustainability.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Data%20Fabric%20For%20Factory%20Operations%20In%20Manufacturing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Amazon Glue and HealthOmics for Genomic Data Processing",
      Description:
        "Discover how Amazon Glue & HealthOmics revolutionize genomic data processing, accelerating breakthroughs in healthcare, research, and beyond.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-glue-and-healthomics-for-genomic-data-processing",
      Links:
        "https://mactores.com/blog/amazon-glue-and-healthomics-for-genomic-data-processing",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/HealthOmics_Amazon_Glue.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title: "Enhancing Ad Targeting with Data Integration Using Amazon Glue",
      Description:
        "Learn how Amazon Glue enhances ad targeting by integrating fragmented data for precise, real-time personalization and improved ROI.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/enhancing-ad-targeting-with-data-integration-using-amazon-glue",
      Links:
        "https://mactores.com/blog/enhancing-ad-targeting-with-data-integration-using-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Ad_Taregeting_Amazon_Glue.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Healthcare and Life Science",
      Title: "Transforming Health Care Data Integration with Amazon Glue",
      Description:
        "Discover how Amazon Glue transforms healthcare data integration, enabling real-time insights, scalability, and enhanced patient outcomes. ",
      ButtonText: "View",
      URL: "https://mactores.com/blog/transforming-health-care-data-integration-with-amazon-glue",
      Links:
        "https://mactores.com/blog/transforming-health-care-data-integration-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Healthcare_Data_Integration_Amazon_Glue.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Improving Production Efficiency with ETL Workflows in Amazon Glue",
      Description:
        "Learn how Amazon Glue enhances production efficiency in manufacturing by streamlining ETL workflows and optimizing processes with real-time data.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/improving-production-efficiency-with-etl-workflows-in-amazon-glue",
      Links:
        "https://mactores.com/blog/improving-production-efficiency-with-etl-workflows-in-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_Glue_Production_Efficiency_ETL.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Media Entertainment",
      Title: "Streamlining Video Production Workflows with Amazon Glue",
      Description:
        "Learn how Amazon Glue streamlines video production workflows with automated ETL, metadata management, and seamless AWS integration, saving time and costs.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-video-production-workflows-with-amazon-glue",
      Links:
        "https://mactores.com/blog/streamlining-video-production-workflows-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_Glue_Video_Production_Workflow.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Automated Database Migration (GenAI) For Internet Software",
      Description:
        "Mactores’ Automated Database Migration with Generative AI enables Internet and Software companies to seamlessly transition from legacy databases to cloud-native environments with minimal disruption.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-u4q2xyg2kazmo?sr=0-21&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Automated%20Database%20Migration%20(GenAI)%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Gaming",
      Title: "Improving Game Monetization Strategies with Amazon Glue",
      Description:
        "Discover how Amazon Glue empowers game developers to boost monetization with data insights, personalized strategies, and optimized ad placements.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/improving-game-monetization-strategies-with-amazon-glue",
      Links:
        "https://mactores.com/blog/improving-game-monetization-strategies-with-amazon-glue",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/AWS_Glue_Gaming_Monetization_Strategies.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Data Platform Migration For Internet Software",
      Description:
        "Our Automated Data Platform Migration solution leverages AWS services like Amazon RDS, AWS Database Migration Service (DMS), Amazon Redshift, and AWS Glue to optimize migrations at scale.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-onq7olujocujg?sr=0-19&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Platform%20Migration%20For%20Internet%20Software.jpg",
    },
  ],

  bedrockList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Eliminate AI Hallucination with Amazon Bedrock Guardrails",
      Description:
        "Discover how the latest Amazon Bedrock Guardrails update from re:Invent 2024 takes AI responsibility and ethics to the next level!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/eliminate-ai-hallucination-with-amazon-bedrock-guardrails",
      Links:
        "https://mactores.com/blog/eliminate-ai-hallucination-with-amazon-bedrock-guardrails",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Bedrock_AI_Hallucinations.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon Bedrock: Boost Business Growth with Multimodal AI",
      Description:
        "Re: Invent 2024: Discover how Amazon Bedrock simplifies multimodal AI development and empowers businesses for data integration and advanced analytics.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-bedrock-boost-business-growth-with-multimodal-ai",
      Links:
        "https://mactores.com/blog/amazon-bedrock-boost-business-growth-with-multimodal-ai",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Bedrock_Multimodal_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Generative AI at Scale: Amazon Bedrock’s Role in AI App Development",
      Description:
        "Discover how Amazon Bedrock simplifies generative AI adoption, accelerates app development, and breaks technical barriers for businesses at scale.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/generative-ai-at-scale-amazon-bedrocks-role-in-ai-app-development",
      Links:
        "https://mactores.com/blog/generative-ai-at-scale-amazon-bedrocks-role-in-ai-app-development",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Generative_AI_App_Development.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Generative AI Engineering Immersion Day",
      Description:
        "Mactores' Engineering Immersion Day: AWS GenAI Dive deep into AWS GenAI with our hands-on workshop. We tailor the day to your needs",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Generative%20AI%20Engineering%20Immersion%20Day.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI Innovation Engine",
      Description:
        "Accelerate your AI initiatives on AWS with our Generative AI Innovation Engine. Our dedicated team explores new AI frontiers, experiments with AWS GenAI services like Amazon Bedrock, and seamlessly integrates breakthroughs into your AWS cloud environment.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-m3vd4q7u46tq4?sr=0-28&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-m3vd4q7u46tq4?sr=0-28&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20Innovation%20Engine.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "GenAI Strategy Immersion Workshop",
      Description:
        "Develop a winning Generative AI strategy for AWS with our immersive workshop. In 3-6 hours, we'll help you identify, prioritize, and plan high-impact AI use cases that align with your business goals and leverage the power of AWS services like Amazon Bedrock.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-7ozhey74oivb2?sr=0-29&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-7ozhey74oivb2?sr=0-29&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/GenAI%20Strategy%20Immersion%20Workshop.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Generative AI Discovery Call ",
      Description:
        "Explore the potential of Generative AI on AWS with our tailored Discovery Call. We'll delve deep into your business challenges and goals, exploring how AWS services like Amazon Bedrock can optimize your workflows and drive innovation.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-3cyqczlxtzl2w?sr=0-30&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-3cyqczlxtzl2w?sr=0-30&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Generative%20AI%20Discovery%20Call%20.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Build Smart Apps with Amazon Nova Models via Amazon Bedrock",
      Description:
        "Looking to build intelligent apps? Discover how AWS uses Amazon Nova via Amazon Bedrock to revolutionize AI apps.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/build-smart-apps-with-amazon-nova-models-via-amazon-bedrock",
      Links:
        "https://mactores.com/blog/build-smart-apps-with-amazon-nova-models-via-amazon-bedrock",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Nova_Bedrock_Smart_Apps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Manufacturing",
      Title:
        "Smarter Manufacturing with Amazon Bedrock's Multi-Agent Collaboration",
      Description:
        "Learn how Amazon Bedrock’s multi-agent collaboration boosts efficiency, reduces errors, and scales manufacturing for smarter, more competitive factories.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/smarter-manufacturing-with-amazon-bedrocks-multi-agent-collaboration",
      Links:
        "https://mactores.com/blog/smarter-manufacturing-with-amazon-bedrocks-multi-agent-collaboration",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Bedrock_Smarter_Manufacturing.jpg",
    },
  ],

  quicksightList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Automate Business Workflows with Amazon Q and QuickSight",
      Description:
        "Discover how re:Invent 2024 updates in Amazon Q and QuickSight transform business automation with AI-driven insights and seamless workflow integration.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automate-business-workflows-with-amazon-q-and-quicksight",
      Links:
        "https://mactores.com/blog/automate-business-workflows-with-amazon-q-and-quicksight",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_QuickSight_Q_Business_Workflow.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "How Amazon QuickSight Powers No-Code Workflow Automation?",
      Description:
        "Amazon QuickSight now automates workflows without code! Analyze data, create visualizations, and get real-time insights with ML-powered predictions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/how-amazon-quicksight-powers-no-code-workflow-automation",
      Links:
        "https://mactores.com/blog/how-amazon-quicksight-powers-no-code-workflow-automation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_QuickSight_No_Code.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Aedeon Data Fabric Solution for Factory Operations",
      Description:
        "Unify, analyze, and optimize your factory operations with our AWS-based Aedeon Data Fabric solution. This solution delivers real-time insights, predictive analytics, and actionable intelligence by utilizing tools like Amazon QuickSight, Amazon Sagemaker, and Amazon Lambda to drive operational excellence.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zraktywc6olx4?sr=0-31&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Aedeon%20Data%20Fabric%20Solution%20for%20Factory%20Operations.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Custom AI Solution for FinTech",
      Description:
        "Mactores' Custom AI solutions for FinTech empower financial institutions to tackle challenges in fraud detection, compliance, and customer engagement through advanced machine learning, big data processing, and predictive analytics.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-lzqx4qyiqryby?sr=0-7&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Custom%20AI%20Solution%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Data Management and Governance For FinTech",
      Description:
        "Unlock your financial data’s potential with Mactores' Data Management & Governance services tailored for FinTech. Enhance decision-making, ensure compliance, and drive innovation with robust frameworks. ",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-sa2murnbgkhhq?sr=0-17&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Data%20Management%20and%20Governance%20For%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Financial Services",
      Title: "Mactores Technology Integration and Optimization for FinTech",
      Description:
        "Mactores empowers FinTech firms to embrace digital transformation confidently by integrating AI, blockchain, and cloud technologies.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-tmud4lvqd2v7c?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Technology%20Integration%20and%20Optimization%20for%20FinTech.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Manufacturing",
      Title: "Mactores Data Fabric For Factory Operations In Manufacturing",
      Description:
        "Optimize your manufacturing operations on AWS with Mactores' Data Fabric. Integrate IoT data from your factory floor with AWS IoT services, leverage Amazon SageMaker for machine learning, and gain real-time insights with Amazon QuickSight to enhance efficiency, reduce downtime, and improve sustainability.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-zrk2ryuj7nmpq?sr=0-10&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Data%20Fabric%20For%20Factory%20Operations%20In%20Manufacturing.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "AI-Driven Product Development For Internet Software",
      Description:
        "Accelerate product development with Mactores' AI-Driven Product Development for Internet Software. Harnessing Data Analytics, Generative AI, and Machine Learning, we empower software companies to innovate faster, optimize operations, and scale efficiently.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-pbt4zsfxcxhsg?sr=0-13&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/AI-Driven%20Product%20Development%20For%20Internet%20Software%20.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
  ],

  novaList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon Nova’s Model Lineup for AI-powered Applications",
      Description:
        "Struggling with AI costs and performance? Amazon Nova offers efficient, customizable AI models for diverse applications. Learn more!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-novas-model-lineup-for-ai-powered-applications",
      Links:
        "https://mactores.com/blog/amazon-novas-model-lineup-for-ai-powered-applications",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Nova_Models_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Understanding Amazon Nova: Foundation Models for Multimodal AI",
      Description:
        "Discover Amazon Nova, a multimodal AI foundation model that integrates text, images, and video to transform industries with smarter, scalable solutions.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/understanding-amazon-nova-foundation-models-for-multimodal-ai",
      Links:
        "https://mactores.com/blog/understanding-amazon-nova-foundation-models-for-multimodal-ai",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Nova_Multimodal_AI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Build Smart Apps with Amazon Nova Models via Amazon Bedrock",
      Description:
        "Looking to build intelligent apps? Discover how AWS uses Amazon Nova via Amazon Bedrock to revolutionize AI apps.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/build-smart-apps-with-amazon-nova-models-via-amazon-bedrock",
      Links:
        "https://mactores.com/blog/build-smart-apps-with-amazon-nova-models-via-amazon-bedrock",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Nova_Bedrock_Smart_Apps.jpg",
    },
  ],
  openSearchList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon OpenSearch Service for Observability and Vector Search",
      Description:
        "Enhance observability & search with Amazon OpenSearch Service. Scale, secure, and analyze data seamlessly with AWS-powered insights. Learn more!",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-opensearch-service-for-observability-and-vector-search",
      Links:
        "https://mactores.com/blog/amazon-opensearch-service-for-observability-and-vector-search",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_OpenSearch_Vector.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "End-to-End Data Analytics Platforms For Internet Software",
      Description:
        "Mactores empowers Internet and Software companies with AI-driven Data Analytics, Generative AI, and Machine Learning solutions to accelerate innovation and optimize operations.",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-spbwziscxvsmu?sr=0-22&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/End-to-End%20Data%20Analytics%20Platforms%20For%20Internet%20Software.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Amazon OpenSearch Meets Amazon Security Lake",
      Description:
        "Discover how Amazon OpenSearch and Security Lake simplify large-scale security data analysis for faster threat detection.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/amazon-opensearch-meets-amazon-security-lake",
      Links:
        "https://mactores.com/blog/amazon-opensearch-meets-amazon-security-lake",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_OpenSearch_Security_Lake.jpg",
    },
  ],
  qList: [
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Automated Testing with Amazon Q Developer Tools",
      Description:
        "Discover re:Invent 2024 update for Amazon Q to enhance developer productivity with AI-powered tools for coding, testing, and troubleshooting.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automated-testing-with-amazon-q-developer-tools",
      Links:
        "https://mactores.com/blog/automated-testing-with-amazon-q-developer-tools",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_Automated_Testing.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "Streamline DevSecOps: Amazon Q’s Integration with GitLab",
      Description:
        "Boost DevSecOps with Amazon Q and GitLab! Enhance security, automate testing, and accelerate software delivery.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamline-devsecops-amazon-qs-integration-with-gitlab",
      Links:
        "https://mactores.com/blog/streamline-devsecops-amazon-qs-integration-with-gitlab",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_DevSecOps.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Automating Code Reviews and Documentation with Amazon Q Developer",
      Description:
        "Learn how Amazon Q Developer Tools automate code reviews and documentation, boosting efficiency, code quality, and collaboration in software development.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/automating-code-reviews-and-documentation-with-amazon-q-developer",
      Links:
        "https://mactores.com/blog/automating-code-reviews-and-documentation-with-amazon-q-developer",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_Automating_Code_Review.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Streamlining AI-Driven Workflows with Amazon Q Business Integrations",
      Description:
        "Simplify AI tasks using Amazon Q Business Integrations. Automate processes, boost teamwork, and maximize efficiency effortlessly across various industries.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/streamlining-ai-driven-workflows-with-amazon-q-business-integrations",
      Links:
        "https://mactores.com/blog/streamlining-ai-driven-workflows-with-amazon-q-business-integrations",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_Business_Integration_Workflow.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title: "AI-Powered Business Intelligence with Amazon Q and AWS AI Tools",
      Description:
        "Discover how Amazon Q and AWS AI tools are revolutionizing business intelligence. Learn about AWS’s AI services, their benefits, and more.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/ai-powered-business-intelligence-with-amazon-q-and-aws-ai-tools",
      Links:
        "https://mactores.com/blog/ai-powered-business-intelligence-with-amazon-q-and-aws-ai-tools",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_AI_Powered_BI.jpg",
    },
    {
      AssetType: "Blog",
      Industry: "Internet Software",
      Title:
        "Unlocking Enterprise Productivity with Amazon Q Business Automation",
      Description:
        "Unlock enterprise productivity with Amazon Q Business, an AI assistant that automates tasks, optimizes workflows, and provides real-time insights.",
      ButtonText: "View",
      URL: "https://mactores.com/blog/unlocking-enterprise-productivity-with-amazon-q-business-automation",
      Links:
        "https://mactores.com/blog/unlocking-enterprise-productivity-with-amazon-q-business-automation",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Blog%20Images/Amazon_Q_Business_Automation_Enterprise_Productivity.jpg",
    },
    {
      AssetType: "Marketplace",
      Industry: "Internet Software",
      Title: "Mactores Generative AI Engineering Immersion Day",
      Description:
        "Mactores' Engineering Immersion Day: AWS GenAI Dive deep into AWS GenAI with our hands-on workshop. We tailor the day to your needs",
      ButtonText: "View",
      URL: "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      Links:
        "https://aws.amazon.com/marketplace/pp/prodview-iz47kbavpfkty?sr=0-12&ref_=beagle&applicationId=AWSMPContessa",
      ImageURL:
        "https://mactores.com/hubfs/AWS%20Marketplace%20Asset%20Hub/Marketplace%20Listing%20Images/Mactores%20Generative%20AI%20Engineering%20Immersion%20Day.jpg",
    },
  ],
};

export default ListData;
